<template>
       <!--<div class="notes several" style="background-repeat:repeat;background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAREAAAEbCAIAAABybSe/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKdSURBVHhe7cexCcAwAAQx7z+Gp/MY6a94SC9Qo3PuA37oga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hhvs+U0KVn3ReFYcAAAAASUVORK5CYII=');">--><!-- | 1 = single | +1 = several | --> <!--@click="destroyComp"-->
  <div class="notes several">
    <h4 class="note-title text-truncate" v-b-tooltip.hover >

      <span :class="icon"></span>
      <span v-if="this.Header.frequency !== 'DAILY MULTIPLES'">{{this.TransDateTitle}}</span>
      <span v-if="this.Header.frequency === 'DAILY MULTIPLES'">{{this.TimeTitle}} </span> - 
      <span v-if="!IsGroupContainer"> {{this.NOTE}}</span>
      <span v-if="IsGroupContainer">{{ this.Header.patientCode === "GroupNote" ? $t('GroupNotesItemRightBar.GroupNoteParent') : this.Header.patientName }}</span>


    </h4>
    <b-icon
      icon="x-circle-fill"
      v-on:click="Close"
      class="rounded-circle notes-close"
      scale=".5"
      font-scale="1.8"
      > <!-- create the CloseNote action. Open notes should be closed with the HIDDEN class -->
    </b-icon>
    <b-dropdown right no-caret size="sm" :text="$tc('Shared.ActionsTxt',2)" :class="(this.changesPending && !NoteIsReadOnly) || this.headersChanged ? 'notesActions notSaved' : 'notesActions'" v-if="this.VisibleActions">
      <b-dropdown-item-button 
        v-on:click="SaveNote(false)" 
        v-if="!NoteIsReadOnly || this.headersChanged" :class="(this.changesPending && !NoteIsReadOnly) || this.headersChanged ? 'notSavedOption' : ''">
        {{ $t('Shared.Save') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button  
        v-on:click="showModalSignandSave()"
        v-if="(!IsGroupNote && !Header.isSigned && !NoteIsReadOnly) || (IsGroupNote && this.Header.IsCommonPart && !Header.isSigned && !NoteIsReadOnly)"
        v-scroll-to="this.vscrollto"
        >{{ $tc('Shared.SignAndSaveBtn',2) }}                            
      </b-dropdown-item-button>

      <b-dropdown-item-button  
        v-on:click="showModalCoSignandSave()"
        v-if="ShowCosignAndSave()"
        v-scroll-to="this.vscrollto"
        >{{ $tc('Shared.CoSignandSaveBtn',2) }}                            
      </b-dropdown-item-button>

      <b-dropdown-item-button  
        v-on:click="EnableTherapist" 
        v-scroll-to="this.vscrollto"
      >{{ $tc('Shared.Therapist',2) }}                            
      </b-dropdown-item-button>

      <b-dropdown-item-button v-if="this.IsShowCosignerTab"
        v-on:click="EnableCosigner"
        v-scroll-to="this.vscrollto"
      >{{ $t('MdNote.Cosigner') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button v-if="this.Header.showBilling"
        v-on:click="EnableTherapies"
        v-scroll-to="this.vscrollto"
      >{{ $t('MdNote.BillingInfo') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button v-if="VisibleVersion && this.MatchSuperVisor && this.Header.customFrame !== 'TFRMDOCUMENTNOTES'"
        v-on:click="EnableVersions"
        v-scroll-to="this.vscrollto"
      > {{ $t('MdNote.Versions') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button v-if="(!this.IsChartReview && !this.Header.isVoid && this.Header.noteStatus != 'New' && this.Header.isSigned && !this.Header.isPendingCoSignature && this.NoteOptions.allowAmendment && !IsGroupNote) ||
                                    (this.IsChartReview && !this.Header.isVoid && this.Header.notesContents !== null && this.Header.notesContents !== '' && this.Header.notesContents !== undefined && !IsGroupNote)"
        v-on:click="ShowAddendums"
        v-scroll-to="this.vscrollto"
      > {{ $t('MdNote.Addendums') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button v-if="!this.Header.isVoid && (!this.EnabledEdit || !this.ShowNote) && !this.IsChartReview"
          v-on:click="VerifySuperVisor"
          v-scroll-to="this.vscrollto"
        > {{ $t('MdNote.Unlock') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button v-if="showPrint && (!this.Header.IsCommonPart || this.Header.IsCommonPart == false)"
        v-on:click="OnclickPrint"       
      >{{ $t('Shared.Report') }}
      </b-dropdown-item-button>

      <!--<b-dropdown-item-button>Unlock</b-dropdown-item-button>-->
      <b-dropdown-item-button v-if="this.Header.noteStatus !== 'New' && !this.Header.isVoid && !NoteIsReadOnly && !IsGroupNote" v-on:click="onReasonBtn">{{ $t('MdNote.Void') }}</b-dropdown-item-button>

      <b-dropdown-item-button v-if="IsWorkFlow" v-on:click="showIWFSummary"
      >{{incidentLabel}}
      </b-dropdown-item-button>
    </b-dropdown>

    <!--clase notesContent Tiene un valor left que hay que tener en cuenta cuando hay varias notas abiertas-->
    <div class="notesContent cutescroll" :id="'ContainerNotesHeader_'+this.Header.Id" v-bind:style="styleWorkflow">
      <!-- COMPONENTS FOR THE NOTE -->
      <!-- TIP: try inserting & TESTING components one by one -->
      <!--Aqui en el encabezado-->

      <!-- QUICK ACCESS STICKY BAR -->
      <div class="NotesStickyBar">
        <div class="ml-1 mt-0">
          <b-button-toolbar>
            <b-button-group class="mr-1">
<!--<b-button v-on:click="showModalSignandSave()" v-b-tooltip.hover v-if="(!IsGroupNote && !Header.isSigned && !NoteIsReadOnly) || (IsGroupNote && this.Header.IsCommonPart && !Header.isSigned && !NoteIsReadOnly) " title="Sign and Save" size="sm" class="ikon med-sign-n-save" variant="outline-primary"></b-button>-->
              <b-button v-if="!NoteIsReadOnly || this.headersChanged" v-on:click="SaveNote(false)" v-b-tooltip.hover title="Save" size="sm" class="ikon med-save" variant="outline-primary"></b-button>
              <b-button v-on:click="showModalSignandSave()" v-b-tooltip.hover v-if="(!IsGroupNote && !Header.isSigned && !NoteIsReadOnly) || (IsGroupNote && this.Header.IsCommonPart && !Header.isSigned && !NoteIsReadOnly) " title="Sign and Save" size="sm" class="ikon med-sign-n-save" variant="outline-primary"></b-button>
              <b-button v-on:click="showModalCoSignandSave()" v-b-tooltip.hover title="Cosign and Save" v-if="this.ShowCosignAndSave() && !NoteIsReadOnly" size="sm" class="ikon med-cosign-n-save" variant="outline-primary"></b-button>

            </b-button-group>
          </b-button-toolbar>
        </div>
      </div>
      <!-- END OF QUICK ACCESS STICKY BAR -->

      <div v-if="VisibleHeader" class="float-right">
        <span id="hidden-wrapper" class="d-inline-block" tabindex="0">
        <b-icon
          icon="x-circle-fill"
          @click="HideHeader"
          class="rounded-circle notes-close"
          scale=".36"
          font-scale="2.6"
          style="position: sticky;"
          > <!-- create the CloseNote action. Open notes should be closed with the HIDDEN class -->
        </b-icon>
        </span>
         <b-tooltip target="hidden-wrapper">{{ $t('MdNote.HideHeader') }}</b-tooltip>
      </div>									
      <div v-if="VisibleHeader" style="margin-left: 0.1em;" class="row" :id="'NotesHeaderParent_'+this.Header.Id" >
        <div class="col-md-12 mx-auto" :id="'NoteHeader_'+this.Header.Id">
          <ul class="nav nav-pills nav-pills-custom" id="pills-tab-custom" role="tablist">                      
            <li class="nav-item">
              <a :class="ClassTabTherapist" 
                 :href="'#'"
                 @click="EnableTherapist"
                 id="pills-home-tab-custom" 
                 data-toggle="pill"  
                 role="tab" 
                 aria-controls="pills-home" 
                 aria-selected="true">
                {{ $tc('Shared.Therapist',2) }}
              </a>
            </li>
            <li class="nav-item"  v-if="this.IsShowCosignerTab">
              <a :class="ClassTabCosigner" 
                 :href="'#'"
                 @click="EnableCosigner"
                 id="pills-profile-tab-custom" 
                 data-toggle="pill" 
                 role="tab" 
                 aria-controls="pills-profile" 
                 aria-selected="false">
                {{ $t('MdNote.Cosigner') }}
              </a>
            </li>
            <li class="nav-item" v-if="this.Header.showBilling">
              <a :class="ClassTabTherapies" 
                 :href="'#'"
                 @click="EnableTherapies"
                 id="pills-contact-tab-custom" 
                 data-toggle="pill"
                 role="tab" 
                 aria-controls="pills-contact" 
                 aria-selected="false">
                {{ $t('MdNote.BillingInfo') }}
              </a>
            </li>  
            <li class="nav-item" v-if="VisibleVersion && this.MatchSuperVisor">
              <a :class="ClassTabVersions" 
                 :href="'#'"
                 @click="EnableVersions"
                 id="pills-versions-tab-custom" 
                 data-toggle="pill"
                 role="tab" 
                 aria-controls="pills-versions" 
                 aria-selected="false">
                {{ $t('MdNote.Versions') }}
              </a>
            </li>        
          </ul>
          <div class="tab-content tab-content-custom-pill flex-row" id="pills-tabContent-custom">
            <div :class="ClassTabContentTherapist" 
                 :id="'Therapist-' + this.Header.Id"
                 role="tabpanel" 
                 aria-labelledby="pills-home-tab-custom"
              >
              <div class="row flex-row">
                <b-col>
                  <MdSelect
                    v-bind:Label="$t('Shared.WrittenBy')"
                    data-field="therapistcode"
                    v-bind:Options="TherapistsList"
                    v-bind:FieldDescription="'fullName'"
                    v-bind:FieldCode="'code'"
                    v-bind:Model="therapistcode"                       
                    v-bind:Multiple="false"
                    v-bind:IsDisabled="NoteIsReadOnly || IsGroupNote && this.Header.IsCommonPart === false"
                    :Input="ChangeDropDownTherapist"
                    v-bind:Tag="Id"
                  />
                </b-col>
                <b-col>
                  <MdCheckBox
                    v-bind:Label="$t('MdNote.SignAndSave')"
                    v-bind:Name="Header.Id+'checkbox-Sign'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-bind:isReadOnly="NoteIsReadOnly || therapistcode.code === '' || IsGroupNote && this.Header.IsCommonPart === false"
                    v-model="Header.isSigned"                   
                    v-on:change="onSignPassword"
                    v-bind:Tag="Id"          
                  />
                </b-col> 
                <b-col>
                  <div id="date-input-sign">
                    <MdEdit 
                      v-bind:Label="$t('MdNote.SignedOn')"
                      v-bind:TypeProp="'text'" 
                      v-bind:Name="'signdatetime'"                    
                      v-model="Header.signedDate"
                      v-bind:Mask="'##/##/#### ##:## AA'"
                      v-bind:isReadOnly="isReadOnlyDateSign"
                      @input="ChangeSignedOn"
                      v-bind:Tag="Id"
                      v-bind:Id="'input_signedon_id'"
                     >                                     
                    </MdEdit>
                  </div>
                  <b-tooltip target="date-input-sign" :title="this.Header.signedDate" variant="info"></b-tooltip>
                </b-col>
                <b-col>
                  <MdEdit 
                    v-bind:Label="$t('MdNote.Service')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'Service'"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-bind:isReadOnly="NoteIsReadOnly || IsGroupNote && this.Header.IsCommonPart === false"
                    v-model="ServiceDate" 
                    @input="ChangeServiceDate"
                    v-bind:Tag="Id"                  
                    >                                     
                  </MdEdit>
                </b-col>
                <b-col>
                  <MdCheckBox
                    v-bind:Label="$t('MdNote.Billable')"
                    v-bind:Name="Header.Id+'checkbox-Billable'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-bind:isReadOnly="NoteIsReadOnly || this.Header.disableBillable"
                    v-model="Header.isBillable"
                    v-on:change="onChangeBillable"
                    v-bind:Tag="Id"
                  />
                </b-col>    
                <b-col v-if="this.Header.noteStatus !== 'New' && !this.Header.isVoid && !IsGroupNote">
                  <MdCheckBox
                    v-bind:Label="$t('MdNote.Void')"
                    v-bind:Name="Header.Id+'checkbox-Void'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-bind:isReadOnly="NoteIsReadOnly"
                    v-model="Header.isVoid"
                    v-on:change="onReason"
                    v-bind:Tag="Id"
                  />
                </b-col>
                <b-col v-if="HoursList.length > 0">
                      <!-- TODO ADD INFO FOR START AND END TIME-->
                      <MdSelect 
                        v-bind:Label="$t('MdNote.STARTTIME')"                      
                        v-bind:Options="this.HoursList"
                        v-bind:FieldDescription="'text'"
                        v-bind:FieldCode="'value'"
                        v-bind:IsDisabled="NoteIsReadOnly"
                        v-bind:Model="StartTime"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownStartTime"
                        v-bind:Tag="Id"
                      />
                    </b-col>
                    <b-col v-if="HoursList.length > 0">
                      <!-- TODO ADD INFO FOR START AND END TIME-->
                      <MdSelect
                        v-bind:Label="$t('MdNote.ENDTIME')"                      
                        v-bind:Options="this.HoursList"
                        v-bind:FieldDescription="'text'"
                        v-bind:FieldCode="'value'"
                        v-bind:IsDisabled="NoteIsReadOnly"
                        v-bind:Model="EndTime"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownEndTime"
                        v-bind:Tag="Id"
                      />
                    </b-col> 
              </div>

              <div class="tab-end">
              </div>
            </div>

            <div :class="ClassTabContentCosigner" 
                :id="'Cosigner-' + this.Header.Id" 
                role="tabpanel" 
                aria-labelledby="pills-profile-tab-custom"
              v-if="this.IsShowCosignerTab">

                  <div class="row flex-row">
                    <b-col>
                      <MdSelect
                        v-bind:Label="$t('MdNote.Cosignedby')"
                        data-field="cotherapistcode"
                        v-bind:Options="CoTherapistsList"
                        v-bind:FieldDescription="'fullName'"
                        v-bind:FieldCode="'code'"
                        v-bind:IsDisabled="NoteIsReadOnly || IsGroupNote && this.Header.IsCommonPart === false"
                        v-bind:ModelArray="CoTherapists"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownCoTherapist"
                        v-bind:Tag="Id"
                      />
                    </b-col>
                    <b-col>
                      <b-row>
                        <b-col>
                          <MdCheckBox
                            v-bind:Label="$t('MdNote.SignAndSave')"
                            v-bind:Name="Header.Id+'_checkbox-Signed'"
                            v-bind:ClassProp="'AlignCheck'"
                            v-bind:CheckedValue="true"
                            v-bind:isReadOnly="NoteIsReadOnly || CoTherapists.code === '' || IsGroupNote && this.Header.IsCommonPart === false"
                            v-bind:UncheckedValue="false"
                            v-model="Header.isCoSigned"
                             v-on:change="onChangeCoSigned"
                             v-bind:Tag="Id"  
                          />
                        </b-col>
                      </b-row>
                    </b-col>              
                    <b-col>
                      <div id="date-input-cosign">
                        <MdEdit 
                          v-bind:Label="$t('MdNote.SignedOn')"
                          v-bind:TypeProp="'text'" 
                          v-bind:Name="'cosigndatetime'"                        
                          v-model="Header.coSignedDate"
                          v-bind:Mask="'##/##/#### ##:## AA'"
                          v-bind:isReadOnly="isReadOnlyDateCoSign"
                          @input="ChangeCoSignedOn"
                          v-bind:Tag="Id"
                          v-bind:Id="'input_consignedon_id'"
                          >                                     
                        </MdEdit>
                      </div>
                      <b-tooltip target="date-input-cosign" :title="this.Header.coSignedDate"  variant="info"></b-tooltip>
                    </b-col>
                  </div>

              <div class="tab-end">
              </div>
            </div>

            <div :class="ClassTabContentTherapies" 
                :id="'Therapies-' + this.Header.Id" 
                role="tabpanel"  v-if="this.Header.showBilling"
                aria-labelledby="pills-contact-tab-custom"
              >
              <b-row>
                <b-col lg="3" md="3" sm="12">
                  <MdLookup
                    v-bind:Label="$t('MdNote.Facility')"
                    v-bind:ModelCode="FacilityCode"
                    v-bind:ModelDescription="FacilityDescription"
                    v-bind:RefCode="this.Header.Id+'_Facility'"
                    v-bind:IsDisabled="NoteIsReadOnly"
                    v-bind:Api="'Facilities'"
                    v-bind:FieldCode="'code'"
                    v-bind:FieldDescription="'company'"
                    :SearchTitle="$t('MdNote.Facility')"
                    :GetValues="onCodeFacilityOk"
                    :SearchFieldCode="'Code'"
                    v-bind:Tag="Id"                     
                  />
                  </b-col>
              </b-row>
             <!-- <MdEditTable 
                v-bind:items="ObjectItems.ArrayVModel"
                v-bind:ObjectItems="ObjectItems"
                v-bind:fields="fields"     
                v-bind:isReadOnly="NoteIsReadOnly"
                v-bind:noteStatus="this.Header.noteStatus"
                v-bind:defaultUnit="this.Header.defaultUnit"
                v-bind:transCode="this.Header.transCode"
                v-bind:Tag="Id"
                v-if="VisibleTableTherapies"
              />-->

              <MdTitle v-bind:Text="$t('Shared.Therapies')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

              <div class="row">
                <div class="col-md-3">      
                    <MdButton
                      v-bind:Label="$t('MdNote.AddTherapy')"
                      v-bind:ClassProp="'btn btn-primary my-3 btn-login btn-block'"
                      v-on:click="AddTherapy"
                      v-bind:isReadOnly="NoteIsReadOnly"
                      v-bind:IconClass="'ti-plus'"                     
                      v-bind:Name="'btn_add_therapy'">
                    </MdButton>
                  </div>
                  <div class="col-sm-12 col-md-9 mb-4">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                      <div class="input-group">
                        <input 
                          type="text" 
                          class="form-control"
                          v-model="Criteria"   
                          @keyup.enter="SearchTherapies">
                          <div class="input-group-append">
                            <div class="input-group-append">
                              <MdButton 
                                v-bind:Name="'btnsearchinput'"
                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                v-bind:IconClass="'mdi mdi-magnify'"                               
                                v-on:click="SearchTherapies">
                              </MdButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
             
              <div class="row">
                <div class="col-md-12">
                  <MdTable 
                    v-bind:fields="fields"
                    v-bind:items="NotesTherapiesByNoteList"
                    v-bind:totalRows="totalRows"
                    v-bind:isReadOnly="false"
                    v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                    v-bind:currentPage="currentPage"
                    v-bind:perPage="perPage"                    
                    :editIconEvent="EditTherapies"
                    v-bind:VisibleEditIcon="!NoteIsReadOnly"
                    :deleteIconEvent="RemoveTherapies"
                    v-bind:VisibleDeleteIcon="!NoteIsReadOnly"
                    :onRowSelected="onRowSelected"                  
                    :onFiltered="onFiltered"
                    :pageChanged="pageChanged"
                    :perPageFunction="PerPageFunction">
                  </MdTable>
                </div>
              </div>

              <div class="tab-end">
              </div>
            </div>

            <div :class="ClassTabContentVersions" 
                :id="'Versions-' + this.Header.Id" 
                role="tabpanel" 
                aria-labelledby="pills-versions-tab-custom"
              v-if="VisibleVersion && this.MatchSuperVisor">
                  <div class="row flex-row">
                    <b-col md="3">
                      <MdSelect
                        v-bind:Label="$t('MdNote.Versions')"
                        data-field="code"
                        v-bind:Options="VersionList"
                        v-bind:FieldDescription="'description'"
                        v-bind:FieldCode="'code'"                        
                        v-bind:Model="VersionModel"  
                        v-bind:AllowEmpty="false"                     
                        v-bind:Multiple="false"
                        v-bind:NoEmitChange="true"
                        :Input="ChangeDropDownVersions"
                        v-bind:Tag="'Versions-' + Id"
                      />
                    </b-col>  
                  </div>

              <div class="tab-end">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- This note is voided! -->
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="this.Header.isVoid">
        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
        <div class="flex-grow-1">
          <span>{{ $t('Msg.NoteVoied') }} {{this.Header.notesContents}}</span>
        </div>
      </div>

      <!-- Your user has Read Only permission for this note -->
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="userHasReadOnlyAccess && !IsChartReview">
        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
        <div class="flex-grow-1">
          <span>{{ $t('MdNote.ReadOnlyPermission') }}</span>
        </div>
      </div>

      <!-- Previous note versions are read only -->
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="this.VersionModel.code > 0 && !IsChartReview">
        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
        <div class="flex-grow-1">
          <span>{{ $t('MdNote.pNoteVersionMsg') }}</span>
        </div>
      </div>

      <!-- This note cannot be changed unless unlocked by a supervisor.. -->
      <!-- //////  ORIGINAL  ////// -->
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="!this.EnabledEdit && this.ShowNote && !this.Header.isVoid && !userHasReadOnlyAccess && !(this.VersionModel.code > 0) && !IsChartReview">
        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
        <div class="flex-grow-1">
          <span>{{ $t('MdNote.pNoteNotEnableEditMsg')}}</span>
        </div>
        <button type="button" class="btn btn-primary ms-3" v-on:click="showSignSuperVisor = true">{{ $t('MdNote.Unlock')}}</button>
      </div>


      <!-- The content of this note cannot be seen unless a supervisor unlock it. -->
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="!this.EnabledEdit && !this.ShowNote && !this.IsChartReview">
        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
        <div class="flex-grow-1">
          <span>{{ $t('MdNote.pNoteNotVisibleMsg')}}</span>
        </div>
        <button type="button" class="btn btn-primary ms-3" v-on:click="showSignSuperVisor = true">{{ $t('MdNote.Unlock')}}</button>
      </div>

      <!-- The content of this note cannot be seen -->
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="!this.EnabledEdit && !this.ShowNote && this.IsChartReview">
        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
        <div class="flex-grow-1">
          <span>{{ $t('MdNote.pNoteNotVisibleChartReviewMsg') }}</span>
        </div>
      </div>

      <!-- This note has an Ademdum -->
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="!this.Header.isVoid && this.Header.notesContents !== null && this.Header.notesContents !== '' && this.Header.notesContents !== undefined && !IsGroupNote">
        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
        <div class="flex-grow-1">
          <span>{{ $t('MdNote.AddendumsMsg') }}</span>
        </div>
        <button type="button" class="btn btn-primary ms-3" v-on:click="VisibleAddendums = true">Addendum</button>
      </div>

      <div class="cutescroll">
        <div style="position:relative;border: solid 1px transparent;">
            <component 
              v-for="(control, index) in DesignerControls" 
              :NoteIsVoid="NoteIsReadOnly" 
              :isReadOnly="IsGroupNote && Header.IsCommonPart === false && control.isCommonControlNote ? true : null"
              :HeaderId="Id" 
              :State="Header.noteStatus"
              :is="control.controlType" 
              :control="control" 
              :IsChartReview="IsChartReview"
              :TransDate="TransDate"
              :NoteId="Header.Id"
              :PatientCode="Header.patientCode"
              :IsCommonPart="Header.IsCommonPart"
              :AdmissionRecId="DataNote.Header.AdmissionRecordId"
              :DateFrom="Header.DateFrom"             
              :ref="'Ref_'+control.controlID"
              v-model="ArrayModel[index]['VM_'+control.controlID]" 
              :key="control.controlID"
              v-bind:id="control.controlID"
              :HideToolBar="NoteIsReadOnly">
            </component>

            <NotesInFiles 
              v-if="this.VisibleDocumentNotes" 
              v-bind:IsChartReview="IsChartReview"
              v-bind:IsReadOnly="NoteIsReadOnly"
              @UpdateFiles="OnUpdateDocumentFile($event)" 
              @FileChanged="OnFileChanged($event)" 
              v-bind:Records="this.FileRecords"/>

              <TherapiesNotes 
                v-bind:TList="this.TherapiesBillingInfoList" 
                v-bind:TUnit="Unit"
                v-bind:Selected="this.TherapySelected"
                v-bind:ModeEdit="this.ModeEdit"
                v-bind:Tag="Id"                
              />
        </div>
      </div>
    </div>

    <MdPasswordDlg v-if="showSignPassword" 
        v-bind:Type="TypePassWord"
        v-bind:UserId="UserIdSelected"
        @getModalPassword="onModalPasswordResult($event)"
    />

    <ModalSignandSave v-if="showSignandSave" 
        v-bind:Type="'Sign'"
        v-bind:TherapistsList="TherapistsList"
        v-bind:NoteIsReadOnly="NoteIsReadOnly"
        v-bind:IsGroupNote="IsGroupNote"
        v-bind:IsCommonPart="this.Header.IsCommonPart"
        v-bind:Id="Header.Id"
        v-bind:therapistcode="therapistcode"
        v-bind:UserId="UserIdSelected"
        @getModalPasswordSignandSave="onModalSignandSaveResult($event)"
    />

    <ModalCoSignandSave v-if="showCoSignandSave" 
        v-bind:Type="'Cosign'"
        v-bind:CoTherapistsList="CoTherapistsList"
        v-bind:NoteIsReadOnly="NoteIsReadOnly"
        v-bind:IsGroupNote="IsGroupNote"
        v-bind:IsCommonPart="this.Header.IsCommonPart"
        v-bind:Id="Header.Id"
        v-bind:CoTherapists="CoTherapists"
        v-bind:UserId="UserIdSelected"
        v-bind:EmployeeCode="this.$store.state.employeeCode"
        @getModalPasswordCoSignandSave="onModalCoSignandSaveResult($event)"
    />

    <MdReasonDlg v-if="showReason"
        @getModalReasonCancel="onModalReasonResult($event)"
        @getModalReasonOK="onModalReasonOK"
    />

    <ModalAddendums v-if="VisibleAddendums" 
        v-bind:Tag="Id"
        v-bind:ReadOnly="this.IsChartReview"
        v-bind:TitleWindow="$t('MdNote.Addendums')" 
        v-bind:AddendumsDesc="this.Header.notesContents"
        @getCodeConditionOK="OnReturnAddendumsDesc($event)"
        @getCodeConditionCANCEL="OnReturnAddendumsDescCancel($event)"
        v-bind:ModalId="this.modalIdAddendumsDesc"
    />

    <MdPasswordDlg v-if="showSignSuperVisor" 
      v-bind:Type="'SuperVisor'"
      v-bind:UserId="UserIdSelected"
      v-bind:VerificationType="'SupervisorPassword'"
      @getModalPassword="onModalPasswordResultSuperVisor($event)"
  />

   

    <NotesRep v-if="isReportVisible" 
        :closeWindow="closeRepWindow"
        :HeaderId="this.Header.Id"
        :FormId="this.Header.notesId"
        :PatientCode="this.Header.patientCode"
        :isReportVisible="isReportVisible"
        :Ver="this.VersionModel.code"
        :ReportTitle="this.getReportTitle()"
        :Transdate="this.TransDate" 
        :Notetime="this.Header.noteTime ? this.Header.noteTime : null" 
        :Void="this.Header.isVoid"
        :NoteName="this.NOTE"
        :IsGroupNote="this.IsGroupNote"
    />
    
    <LoadingNote v-bind:IsLoading="this.Load" /> 
  </div>	
</template>

<script>
import Vue from 'vue';
import moment from 'moment'
import NotesInFiles from '@/components/NotesComponents/MdNotesInFiles'
import { EventBus } from '../../../event-bus.js';
import Utils from '../../../common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import ClinicalService from '@/services/clinical.service'
// import LocalStorage from '@/services/storage.service'
import GlobalService from '@/services/global.service'
import PatientService from '@/services/patient.service' 
import TherapistService from '@/services/therapist.service'
import AttachmentNotes from '@/services/attachmentnotes.service'
import TherapiesNotes from "@/components/MedEZComponents/notes/modalTherapiesNotes";
import TIssSignatureCapture from '@/components/NotesComponents/MdSignatureCaptureCN'
import TIssImage from '@/components/NotesComponents/MdImage'
import TIssImageEditor from '@/components/NotesComponents/MdImageEditor'
import TIssLabel from '@/components/NotesComponents/MdLabelCN'
import TIssEdit from '@/components/NotesComponents/MdEditCN'
import TIssMemo from '@/components/NotesComponents/MdMemoCN'
import TIssRichEdit from '@/components/NotesComponents/MdMemoCN'
import TfmWileyModalitytoObjective from '@/components/NotesComponents/MdWileyModalitytoObjectiveCN'
import TIssHtmlEditor from '@/components/NotesComponents/MdRichEditCN'
import TIssCheckBox from '@/components/NotesComponents/MdCheckBoxCN'
import TIssFormTable from '@/components/NotesComponents/MdEditTableCN'
import TIssComboBox from '@/components/NotesComponents/MdSelectCN'
import TIssCheckListBox from '@/components/NotesComponents/MdCheckListBoxCN'
import TIssDateTimePicker from '@/components/NotesComponents/MdDateTimePickerCN'
import TIssRadioGroup from '@/components/NotesComponents/MdRadioGroupCN'
import TIssListBox from '@/components/NotesComponents/MdListBox'
import TIssImageBG from '@/components/NotesComponents/MdImage'
import ShowMessage from '@/components/messages/ShowMessage.js'
import TIssMaskEdit from '@/components/NotesComponents/MdMaskEditCN'
import LoadingNote from '@/components/template/loading/Loading'
import GroupNotesService from '@/services/groupnotes.service'
import StorageService from '@/services/storage.service'
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)



export default {
  props: {
    IsChartReview:[Boolean],
    NOTE: [String,Number,Object], 
    TransDate:String,   
    Time:String,
    Header:Object,
    VisibleIconinTitle:[Boolean],
    ProgramCode:[String,Number],
    CategoryCode:[String],
    DateFrom:String,
    NotePatientInfo: Object,
    Location:String,
    IsWorkFlow: Boolean,
    IncidentID: String,
    IncidentDate: String,    
    AdmissionRecordId: String,
    VisibleNoteHeader:Boolean,
    IsGroupNote: Boolean,
    IsGroupContainer: Boolean,
    GroupNoteStatus: String,
    PatientList: [Object, Array],
    IsClinicalNote: Boolean,
    IsSchedulerNote: Boolean,
  },
  
  components: {    
    TIssLabel,TIssSignatureCapture,TIssEdit,TIssMemo,TIssCheckBox,
    TIssFormTable,TIssComboBox,TIssCheckListBox,TIssDateTimePicker,
    TIssRichEdit,TIssImage,TIssImageEditor,TfmWileyModalitytoObjective,
    TIssListBox,TIssImageBG,TIssRadioGroup,TIssMaskEdit,LoadingNote,
    NotesInFiles,TIssHtmlEditor,TherapiesNotes
  },
  mixins: [EventBus, ShowMessage, DBFunctions],
  data: function() {
    return { 
      modalIdAddendumsDesc:"modalIdAddendumsDesc",
      VisibleAddendums:false,
      EnabledEdit:true,
      ShowNote:true,
      VersionList:[],
      DesignerControlsCopy:[],
      showSignSuperVisor:false,
      VersionModel:{code:0,description:this.$t('MdNote.Current')},
      isSupervisor:StorageService.IsSupervisor(),
      VisibleVersion:false,
      isResetGroupNoteVisible: false,
      IsNew:true, 
      isReadOnlyDateSign:true,
      isReadOnlyDateCoSign:true,
      Unit:"",
      icon:"",
      Criteria:"",
      ModeEdit:false,
      TherapySelected:{},
      IsShowCosignerTab:false,
      VisibleHeader: false,
      VisibleActions:false,
      ClassTabTherapist:'',
      ClassTabCosigner:'',
      ClassTabTherapies:'',
      ClassTabBillingInfo:'',
      ClassTabVersions:'',
      DesignerControls:[],
      EmployeeCode:'',
      TypePassWord:'',
      UserIdSelected:'',
      totalRows:0,
      ShowMessageIsEmpty:false,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15 ],
      therapistcode:{
        "code": "",
        "firstName": "",
        "fullName": "",
        "lastName": "",
      },
      CoTherapists:{
        "code": "",
        "firstName": "",
        "fullName": "",
        "lastName": "",
      },
      TherapistsList:[],
      CoTherapistsList:[],
      NotesTherapiesByNoteList:[],
      NotesTherapiesByNoteListCopy:[],
      TherapiesBillingInfoList:[],
      TherapiesBillingInfoListCopy:[],       
      HoursList:[],
      fields: [
            { key: 'name', sortable: true, label: this.$t('MdNote.Therapy'), class: 'text-rigth' },
            { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth hidden' },
            { key: 'units', sortable: true, label: this.$t('MdNote.Units'), class: 'text-rigth' },
          ],
     
      Controls:[],
      VisibleTableTherapies:false,
      RegistrationInfo:{},
      DataSaved:[],
      ReasonSelected:{},
      TransDateTitle:moment(this.Header.transDate).format("ddd, ll"),
      TimeTitle:"",
      ClassTabContentTherapist:'',
      ClassTabContentCosigner:'',
      ClassTabContentTherapies:'',
      ClassTabContentBillingInfo:'',    
      ClassTabContentVersions:'',
      ArrayModel: [],
      FileRecords:[],
      DocumentListChanged:false,
      showPrint:false,
      isReportVisible: false,
      VisibleDocumentNotes:false,
      TmpArrayModel:[], 
      ClassTabContent:'tab-pane fade', // tab-pane fade active show
      ClassTabContentActive:'tab-pane fade active show',
      ClassTab:'nav-link', //nav-link  active 
      ClassTabActive:'nav-link active', //nav-link  active 
      itemsNotesHeader: [],
      ServiceDate:Utils.formatterDate(this.Header.serviceDate),
      FacilityCode:"",
      FacilityDescription:"",
      StartTime:{'text':'','value':''},
      EndTime:{'text':'','value':''},        
      NoteOptions:{},
      Id:this.Header.Id,
      CopyIsVoid:this.Header.isVoid,
      DataNote:{
        Header:{
          StartTimeString:"",
          SignedDate:this.Header.signedDate === "" ? null :this.Header.signedDate,
          CosignedDate:this.Header.coSignedDate === "" ? null : this.Header.coSignedDate,
          EndTimeString:"",
          Chartno:this.Header.chartno, // ready to send         
          Cotherapistcode:"", // ready to send
          Headerid:this.Header.Id.toString(), // ready to send
          Issigned:this.Header.isSigned, // ready to send
          Isvoid:this.Header.isVoid, // ready to send
          IsScheduled:true, // ready to send
          Notesid:this.Header.notesId, // ready to send
          Patientcode:this.Header.patientCode, // ready to send
          Servicedate:this.ServiceDate,       // ready to send
          Therapistcode:"", // ready to send
          transCode:this.Header.transCode, //ready to send
          Transdate:this.TransDate, //ready to send
          Isbillable:this.Header.isBillable, // ready to send
          notescontents: "", // ready to send
          StartTime:this.StartTime,  // ready to send
          EndTime:this.EndTime,  // ready to send
          FacilityCode:"", // ready to send
         // poscode: "", // falta
          ProgCode:this.ProgramCode, // ready to send         
          IsPendingCoSignature:this.Header.isPendingCoSignature, // ready to send
          IsCoSigned:this.Header.isCoSigned, // // ready to send
          Amendment:"", // ready to send
          groupCreationId: this.IsGroupNote ? this.Header.groupCreationId : null,
          AdmissionRecordId: this.$route.params.recordid ? this.$route.params.recordid : this.AdmissionRecordId, // ready to send
          ApptType:"", // ready to send
          NoteTime:null, // ready to send
          Activityid: this.IsSchedulerNote || this.IsGroupNote ? this.Header.activityId : null,
        },
        details: [       
        ],
        therapies: [],
        attachments:[],
        workflow: {
          incidentID: this.IncidentID,
          workflowRecID: this.Header.workflowRecID,
          processRecID: this.Header.processRecID,
          actionRecID: this.Header.actionRecID,
        }
      },
      ObjectItems:{
        ArrayValues:this.TherapiesBillingInfoList,
        ArrayVModel:[],
      },
      therapistList: [
          { value: null, text: '' },  
          { value: 'MEDEZ', text: 'Medez' }
      ],
      therapiesList: [
          { value: null, text: '' },  
          { value: 'ADM', text: this.$t('ENUM.PITEMS.Admissions') }
      ],
      voidList: [
          { value: null, text: '' },  
          { value: '1', text: '1' },
          { value: '2', text: '2' },
          { value: '3', text: '3' }
      ],     
      Facility:{ "description": '' },
      Pos:{ "description": '' },
      showSignPassword: false,
      showSignandSave:false,
      showCoSignandSave:false,
      showReason:false, 
      vscrollto :{
          el: '#NoteHeader_'+this.Header.Id,
          container: '#ContainerNotesHeader_'+this.Header.Id,
          duration: 500,
          lazy: false,
          easing: 'linear',
          offset: 0,
          force: true,
          cancelable: true,
          x: false,
          y: true
        },
      userHasReadOnlyAccess: this.Header.userHasReadOnlyAccess,
      NoteIsReadOnly: false,
      Load: false,
      headersChanged: false,
      changesPending: false,
      styleWorkflow: {
        overflowX: this.IsWorkFlow ? 'hidden' : ''
      },
      isShowingIncident: false,
      incidentLabel: this.$t('MdNote.vInci'),
      nextItemFromGroupNotes: null,
      actionFromGroupNote: '',
      pathAfterSave: null,
      savingFromIWF: false
      }
  },

  methods: {

    VerifySuperVisor(){
      this.showSignSuperVisor = true;
    },

    resetGroupNote() {
      this.$emit('load', true);
      GroupNotesService.deleteANote(this.Header.groupCreationId, this.Header.patientCode)
          .then(async () => {
            EventBus.$emit('onResetFromMdNote',this.Header.patientCode);
            this.$emit('load', false);
          })
          .catch((error) => {            
              if (!error.response) {
                  this.showMessage(this.$t('GroupNotes.GroupNotes'),this.$t('Msg.retrieveInfo'),"error");
              } else if(error.response.status === 400 ){
                  this.showMessage(this.$t('GroupNotes.GroupNotes'),error.response.data.errorMessage,"error");
              } else if(error.response.status === 500){
          this.showMessage(this.$t('GroupNotes.GroupNotes'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
          this.showMessage(this.$t('GroupNotes.GroupNotes'),error.response.data.errorMessage,"error");
              } 
              this.$emit('load', false);
          });
    },

    askResetGroupNote() {
      this.$bvModal.msgBoxConfirm(this.$t('MdNote.resetMsg'), {
          title: this.$t('GroupNotes.GroupNotes'),
          okTitle: this.$t('Shared.Reset'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value){
          this.resetGroupNote()
        } 
      })
    },

    SearchTherapies(){
      //this.adm.teamMembers = this.TeamMemberCopy;
        var arr = this.NotesTherapiesByNoteListCopy;
        var temp = [];
         if(this.Criteria){
              arr.forEach(element => {
                 if(element.code.toUpperCase().includes(this.Criteria.toUpperCase()) || element.name.toUpperCase().includes(this.Criteria.toUpperCase()) || element.units.toString().toUpperCase().includes(this.Criteria.toUpperCase())
                 ){
                      temp.push(element);
                 }
              });
         }else{
             temp = this.NotesTherapiesByNoteListCopy;
         }
        this.NotesTherapiesByNoteList = temp;
          
    },

    EditTherapies(row){
      this.TherapySelected = row;
      this.ModeEdit = true;
      this.$bvModal.show("bv-modal-therapiesNotes");
    },

    setChangedStatus(){
      var changed = {
        "Changed":true,
        "ComponentName":'MdNote',
        "Tag": this.Id
        }
        EventBus.$emit('onChanged', changed);
    },

    RemoveTherapies(row){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value){
              const index = this.NotesTherapiesByNoteList.findIndex(item => item.code === row.code) 
                  if (~index) {
                    this.NotesTherapiesByNoteList.splice(index, 1);
                    this.setChangedStatus();
                    this.populateDataNoteTherapies(this.NotesTherapiesByNoteList);
                    this.NotesTherapiesByNoteListCopy = [...this.NotesTherapiesByNoteList];
                    this.popTherapies(row.code)
                    //this.NotesTherapiesByNote(); 
                  }
            }
          })
    },

    AddTherapy(){
      this.ModeEdit = false;
      this.$bvModal.show("bv-modal-therapiesNotes");
    },

    OnReturnAddendumsDescCancel(){
      this.VisibleAddendums = false;
    },

    OnReturnAddendumsDesc(data){
      //this.headersChanged = true;     
      if(this.Header.notesContents.trim() !== data.description.trim()){
        this.headersChanged = true;
      }
      this.VisibleAddendums = false;
      this.Header.notesContents = data.description;
      this.DataNote.Header.notescontents = data.description;
    },

    onRowSelected(){

    },

    onFiltered(){

    },

    pageChanged(){

    },

    PerPageFunction(){

    },

    EnableTherapist(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabTherapist = 'nav-link active';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabContentTherapist = 'tab-pane fade active show';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade';
      this.ClassTabVersions = 'nav-link';
      this.ClassTabContentVersions = 'tab-pane fade';
    },

    EnableCosigner(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link active';
      this.ClassTabContentCosigner = 'tab-pane fade active show';
      this.ClassTabTherapist = 'nav-link';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabContentTherapist = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade';
      this.ClassTabVersions = 'nav-link';
      this.ClassTabContentVersions = 'tab-pane fade';
    },
    EnableTherapies(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabTherapist = 'nav-link';
      this.ClassTabTherapies = 'nav-link active';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabContentTherapist = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade active show';
      this.ClassTabContentBillingInfo = 'tab-pane fade';
      this.ClassTabVersions = 'nav-link';
      this.ClassTabContentVersions = 'tab-pane fade';
    },

    EnableVersions(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabTherapist = 'nav-link';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabContentTherapist = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade';

      this.ClassTabVersions = 'nav-link active';
      this.ClassTabContentVersions = 'tab-pane fade active show';
    },


    EnableBillingInfo(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabTherapist = 'nav-link';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link active';
      this.ClassTabContentTherapist = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade active show';
      this.ClassTabVersions = 'nav-link';
      this.ClassTabContentVersions = 'tab-pane fade';
    },

    InitializeClass(){    
      this.ClassTabTherapist = 'nav-link active';
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabVersions = 'nav-link';
     
      this.ClassTabContentTherapist = 'tab-pane fade active show';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade';
      this.ClassTabContentVersions = 'tab-pane fade';
    },

   
    ShowAddendums(){
      this.VisibleAddendums = true;
    },

    OnUpdateDocumentFile(file){
      this.FileRecords = file;
    },

    HideHeader(){
      this.VisibleHeader = false;
    },
    getControls(){
      var params = {
        "NoteId":this.Header.notesId,
        "PatientCode":this.IsWorkFlow ? this.Header.patientCode : this.$route.params.id,
        "RecordId":this.$route.params.recordid,
        "TransDate":Utils.formatterDateToString(this.TransDate)
      };
      ClinicalService.getControls(params)
          .then((response) => {              
            if(response.data && response.data.length > 0){
              this.DesignerControls = response.data;               
              this.fillArrayModel(this.DesignerControls);
            }
            this.Load = false;
          })
          .catch(error => {
            if (error.response) {
              this.Load = false;
              this.showMessage(this.$t('MdNote.GetControlsNotes'),error,"error");
            } 
          });  
    },

    async getProgramCodeByRecordId(){     
      if(this.Header.admissionRecordId !== null && this.Header.admissionRecordId === ""){
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.Header.admissionRecordId);
      }else{
        this.RegistrationInfo.programCode = "";
      }        
    },

    saveNoteFromIWF() {
      this.SaveNote(true)
    },

    async saveNoteFromGroupNotes() {     
      await this.SaveNote(true)
      this.$emit('remove');
    },

    async Close(){   
     if(this.isComponentsChanged() || this.headersChanged || this.DocumentListChanged) {
       this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('MdNote.SaveChanges'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning',
          id:'confirmNotes'
       })
          .then(value => {
            if(value){
              this.SaveNote(true)
            } else {
              this.$emit('remove');
              this.setNoteIsEditing(this.Id, false);
              EventBus.$emit('CloseMdNote');
            }
          })
     } else {
       this.$emit('remove');
       EventBus.$emit('CloseMdNote');
     }
    },



    getDocuments(){
       AttachmentNotes.getAttachmentNotes(this.Header.Id)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.FileRecords = response.data;
              for(var i = 0; i < this.FileRecords.length; i++){
                this.FileRecords[i].base64 = "";
                this.FileRecords[i].new = false;
                this.FileRecords[i].isreadonly = true;
                var Vname = this.FileRecords[i].name;
                var ext = "";
                var pos = Vname.lastIndexOf('.');
                if(pos !== -1){  
                   this.FileRecords[i].name = Vname.substring(0, pos);
                   ext =  Vname.substring(pos).toUpperCase();
                   this.FileRecords[i].extension = ext
                }
                this.FileRecords[i].Image = Utils.getType(ext);
              }
            }
            this.VisibleActions = true;
            this.VisibleDocumentNotes = true;          
            this.Load = false;
          })
          .catch(error => {
              this.Load = false;
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetTherapists'),error,"error");
            } 
          });  
    },

    OnFileChanged(e){     
      this.DocumentListChanged = e;
      this.changesPending = true;
      this.setNoteIsEditing(this.Id,true);
    },


    setWorkflowNoteValues() {
      this.Header.noteStatus = !this.itemsNotesHeader.notesid ? 'New' : ''
      if (!this.therapistcode.code) {
        this.SetTherapists()
      }
      
      return this.itemsNotesHeader.notesid ? false : true
    },

    TableHaveFieldTime(arr){
      for(var i = 0; i < arr.length; i++){
        if(arr[i].dataType === "time"){
          return true;
        }
      }
      return false;
    },

    getKeyFieldTime(arr){
      var keylist = [];
      for(var i = 0; i < arr.length; i++){
        if(arr[i].dataType === "time"){
          keylist.push(arr[i].key);
        }
      }
     return keylist;
    },

    FormatTimeMilitary(time){
      return moment(time, "hh:mm A").format("HH:mm");
    },

    getControlsAndValues(ver){
      this.DesignerControls = [];
      this.ArrayModel = [];
      if(this.Header.customFrame === 'TFMDFORMDESIGNERRENDERING'){

        var params = {
        "NoteId":this.Header.notesId,
        "PatientCode": this.IsWorkFlow || this.IsGroupNote ? this.Header.patientCode : this.$route.params.id,
        "RecordId": this.IsWorkFlow || this.IsGroupNote ? this.Header.recordid : this.$route.params.recordid,
        "ProgramCode": this.ProgramCode,
        "TransDate": this.IsWorkFlow ? Utils.formatterDateToString(this.IncidentDate) : Utils.formatterDateToString(this.TransDate),
        "HeaderId":this.Header.Id,
        "TransCode":this.Header.transCode,
        "IsNew": this.IsWorkFlow ? this.setWorkflowNoteValues() : this.Header.noteStatus === 'New'?true:false,
        "IncidentId": this.IsWorkFlow ? this.IncidentID : null,
        "IsGroupNote": this.IsGroupNote,
        "IsCommonPart": this.IsGroupNote ? this.Header.IsCommonPart : null,
        "GroupNodeHeaderId" : this.IsGroupNote ? this.Header.GroupNodeHeaderId : null,
        "ActivityId": this.Header.ActivityId ? this.Header.ActivityId : null, 
        "Ver":ver
      };
      ClinicalService.getControlsAndvalues(params)
          .then((response) => {              
            if(response.data && response.data.length > 0){
              /*this.DesignerControls = response.data; */ 
              this.DesignerControls = [];              
              var arr = response.data;            
              var l = arr.length; 
              for(var i = 0; i < l; i++){
                  let valueItems = {};
                  var ControlType = arr[i].controlType;
                  var ControlId = arr[i].controlID;
                  valueItems["Type"] = arr[i].controlType;    
                  valueItems["ControlId"] = arr[i].controlID;
                  valueItems["Extension"] = arr[i].extension;
                  valueItems["Required"]  = arr[i].isRequired;
                  valueItems["Changed"]  = false;
                  valueItems["NoteId"] = this.Id

                  if(ControlType === 'TIssEdit' || ControlType === 'TIssMemo' || 
                     ControlType === 'TIssRichEdit'|| ControlType === 'TIssMaskEdit' || ControlType === 'TIssHtmlEditor' ){
                      valueItems["VM_"+ControlId] = arr[i].contents;
                  }else if(ControlType === 'TIssDateTimePicker'){
                      valueItems["VM_"+ControlId] = arr[i].contents ? Utils.formatterDate(arr[i].contents) : "";
                  }
                  else if(ControlType === 'TIssImageBG' || ControlType === 'TIssSignatureCapture' || ControlType === 'TIssImage' || ControlType === 'TIssImageEditor'){ 
                      valueItems["VM_"+ControlId] = arr[i].imageContents;
                  }else if(ControlType === 'TIssCheckListBox'){
                      valueItems["VM_"+ControlId] = arr[i].selectedValues;
                  }else if(ControlType === 'TIssRadioGroup'){
                      valueItems["VM_"+ControlId] = arr[i].selectedValues[0];
                  }                  
                  else if(ControlType === 'TIssComboBox'){
                      valueItems["VM_"+ControlId] = arr[i].selectedValues[0];
                  }
                  else if(ControlType === 'TIssCheckBox'){
                      valueItems["VM_"+ControlId] = arr[i].checked;
                  }else if(ControlType === 'TIssFormTable'){

                   if(this.TableHaveFieldTime(arr[i].table.columns)){
                      let keylist = this.getKeyFieldTime(arr[i].table.columns);
                      if(keylist.length > 0 ){
                        if(arr[i].table.rows !== null){
                          for(var r = 0; r < arr[i].table.rows.length; r++){
                            for(var x = 0; x <keylist.length; x++){      
                              if(arr[i].table.rows[r][keylist[x]] !== null && arr[i].table.rows[r][keylist[x]] !== ""){
                                arr[i].table.rows[r][keylist[x]] = this.FormatTimeMilitary(arr[i].table.rows[r][keylist[x]]);
                              }  
                            }
                        }
                        }
                        
                      }
                    }


                      var itemstable = arr[i].table.rows !== null?arr[i].table.rows:[];
                      valueItems["VM_"+ControlId] = itemstable;

                      for(var j = 0 ; j < arr[i].table.columns.length; j++ ){
                        if(arr[i].table.columns[j].isRequired){
                            arr[i].table.columns[j].thClass = '';
                        }
                      }

                      valueItems["Columns"]  =  arr[i].table.columns;


                  }else if(ControlType === 'TfmWileyModalitytoObjective'){
                    if(this.Header.noteStatus === 'New'){
                      valueItems["VM_"+ControlId] = {};
                    }else{                     
                      valueItems["VM_"+ControlId] = this.getTreatmentPlanByControlId(arr,ControlId);                     
                    }
                  }
                  else{
                      valueItems["VM_"+ControlId] = "";
                  }
                  this.ArrayModel.push(valueItems);
              }  
              this.DesignerControlsCopy = [...response.data];      
              this.DesignerControls = response.data;
              this.VisibleActions = true;
            }
            this.Load = false;
          })
          .catch(error => {
            if (error.response) {             
              this.showMessage(this.$t('MdNote.ControlsValues'),error,"error");
            } 
             this.Load = false;
          }); 
      } else if(this.Header.customFrame === 'TFRMDOCUMENTNOTES'){
          this.getDocuments();
      }
    },

    getTreatmentPlanByControlId(arr,ControlId){
      var l = arr.length;
      let result = {};
      for(var i = 0; i < l; i++){       
        if(arr[i].controlID === ControlId){       
          result = arr[i].treatmentPlan;
          break;
        }
      }
      return result;
    },

    getTypebyControlId(ControlId){
      var l = this.DesignerControls.length;
      for(var i = 0; i < l; i++){
        if(this.DesignerControls[i].controlID === ControlId){
          return this.DesignerControls[i].controlType;
        }
      }
    },

    getControlById(ControlId){
    var l = this.DesignerControls.length;
      for(var i = 0; i < l; i++){
        if(this.DesignerControls[i].controlID === ControlId){
          return this.DesignerControls[i];
        }
      }
    },

    getControl:function(ControlId){
    var l = this.DesignerControls.length;
      for(var i = 0; i < l; i++){
        if(this.DesignerControls[i].controlID === ControlId){
          return this.DesignerControls[i];
        }
      }
    },

    getPosByText(arr,text){
      var pos = -1;
      for(var i = 0; i < arr.length; i++){
        if(arr[i] === text){
          pos = i;
        }
      }
    return pos;
    },

    PopulateDataSaved(){
      var l = this.DataSaved.length;  
      var listbox = [];
      var objlistbox = {};
      for(var i = 0; i < l; i++){    
        const code = this.DataSaved[i].controlID;
        var type = this.getTypebyControlId(code);
        const finalCode = "VM_"+code;
        var cts = this.DataSaved[i].contents;     
          if(type === 'TIssImageEditor' || type === 'TIssSignatureCapture' || type === 'TIssImage'){
            var imagearray = [];
            var image = this.DataSaved[i].objectContents;
          imagearray.push(image);
          this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = imagearray;
          }

        else if(type === 'TIssListBox'){
            //controlFontForHtml
            for(var k = 0; k < cts.length; k++){
              var item = this.getControlById(code);
                objlistbox.text = cts[k];               
                if(item.controlFontForHtml){
                  objlistbox.style = 'color:'+item.controlFontForHtml.split(',')[3];
                }            
                objlistbox.id = this.getPosByText(item.contents,cts[k]);
                listbox.push(objlistbox);
                objlistbox = {};
            }
              this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = listbox;
            }
        else if(type === 'TIssEdit' || type === 'TIssMemo' || type === 'TIssRichEdit' || type === 'TIssMaskEdit' || type === 'TIssHtmlEditor'){
          this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = cts[0];
        }else if(type === 'TIssCheckListBox'){     
          this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = cts;     
        }else if(type === 'TIssCheckBox'){
          var c = cts[0] === 'Y'?true:false;
          this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] =  c;      
        }else if(type === 'TIssRadioGroup'){      
          this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = cts[0];  
        }else if(type === 'TIssDateTimePicker'){
          if(cts[0] !== '' && cts[0] !== null){
            var datesaved11 = Utils.formatterDate(cts[0]);
            this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = datesaved11;
          }
        }else if(type === 'TIssComboBox'){     
          this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = cts[0];
        }else if(type === 'TIssFormTable'){
          var table =  this.DataSaved[i];
          if(table.tableValues !== null) {
              this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = table.tableValues.rows;
          }    
            
        }
        
      }
      this.VisibleActions = true;
    },

    

    SetReadOnlyAllControls(){
      EventBus.$emit('onSetReadOnly', true);
    },

    emitsAfterSaveGroupNote() {
      EventBus.$emit('updateGroupStatusOnSave',this.Header.Id)
      EventBus.$emit('updateNoteStatusOnMoveSave',this.Header.Id, this.Header.isSigned || this.Header.isCoSigned)
      this.isResetGroupNoteVisible = true
      EventBus.$emit('showFinalizeGroupNote')
      EventBus.$emit('showDeleteGroupNote')
      EventBus.$emit('updatePatients', this.Header.Id);
    },

   async SaveHeader(){
    this.DataNote.Header.IsGroupNote = this.IsGroupNote ? true : false
    this.DataNote.Header.IsCommonPart = this.IsGroupNote && this.Header.patientCode === "GroupNote" ? true : false
     await ClinicalService.SaveNote(this.DataNote)
        .then((response) => {         
           if(response.status === 200){
             this.Load = false;  
             this.getNotesHeader('');   
             this.ExecuteVersion();        
              this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                        title: this.$t('MdNote.SavingNote'),
                        autoHideDelay: 1000,
                        variant: "success"
               });
               if(this.Header.isVoid){
                 this.CopyIsVoid = true;
               }
               this.setNoteIsEditing(this.Id, false);               
               if (this.IsWorkFlow) {
                EventBus.$emit('updateChangesIWF');
                EventBus.$emit('loadMenuOnCloseNote');
               }
               if (this.IsGroupNote || this.GroupNoteStatus == 'Finalized' && this.actionFromGroupNote == '') {
                EventBus.$emit('onGotoGroupNote', this.nextItemFromGroupNotes)
                this.emitsAfterSaveGroupNote()
               }
               if (this.actionFromGroupNote != '') {
                this.emitsAfterSaveGroupNote()
                EventBus.$emit('onDoAction', this.actionFromGroupNote);
               }

               if (this.pathAfterSave) {
                this.goToAfterSave()
               }
           }
        })
        .catch(error => {
          this.Load = false;
          /*if (!error.response) {
              this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.NotAbleToUpdate'),"error");
          }
          else {
            this.showMessage("Save Note",error.response.data.errorMessage,"error");
          } */
          if (!error.response) {
                   this.showMessage(this.$t('MdNote.SavingNote'),this.$t('Msg.retrieveInfo'),"error");
                 }          
              else if(error.response.status === 400 ){
                 this.showMessage(this.$t('MdNote.SavingNote'),error.response.data.errorMessage,"error");
              }else if(error.response.status === 500){
                this.showMessage(this.$t('MdNote.SavingNote'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
                  this.showMessage(this.$t('MdNote.SavingNote'),error.response.data.errorMessage,"error");
              } 
        });  
    },

   async UpdateNote(){
        this.DataNote.Header.IsGroupNote = this.IsGroupNote ? true : false
        this.DataNote.Header.IsCommonPart = this.IsGroupNote && this.Header.patientCode === "GroupNote" ? true : false
        await ClinicalService.UpdateNote(this.Header.Id,this.Header.notesId,this.DataNote)
        .then((response) => {         
           if(response.status === 200){ 
             this.Load = false;   
             this.getNotesHeader(''); 
             this.ExecuteVersion();     
              this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                        title: this.$t('MdNote.SavingNote'),
                        autoHideDelay: 1000,
                        variant: "success"
               });

              if(this.Header.isVoid){
                 this.CopyIsVoid = true;
               }
               this.setNoteIsEditing(this.Id, false);
               
               if (this.IsWorkFlow) {
                EventBus.$emit('updateChangesIWF');
                EventBus.$emit('loadMenuOnCloseNote');
               }
               if (this.IsGroupNote || this.GroupNoteStatus == 'Finalized' && this.actionFromGroupNote == '') {
                EventBus.$emit('updateNoteStatusOnMoveSave',this.Header.Id, this.Header.isSigned || this.Header.isCoSigned)
                EventBus.$emit('onGotoGroupNote', this.nextItemFromGroupNotes)
                this.isResetGroupNoteVisible = true
               }
               if (this.actionFromGroupNote != '') {
                EventBus.$emit('updateNoteStatusOnMoveSave',this.Header.Id, this.Header.isSigned || this.Header.isCoSigned)
                this.isResetGroupNoteVisible = true
                EventBus.$emit('onDoAction', this.actionFromGroupNote);
               }

               if (this.savingFromIWF)  {
                this.$emit('remove');
                EventBus.$emit('CloseMdNote');
               }

               if (this.pathAfterSave) {
                this.goToAfterSave()
               }        
           }
        })
        .catch(error => {
           this.Load = false;
           if (!error.response) {
                   this.showMessage(this.$t('MdNote.SavingNote'),this.$t('Msg.retrieveInfo'),"error");
                 }          
              else if(error.response.status === 400 ){
                 this.showMessage(this.$t('MdNote.SavingNote'),error.response.data.errorMessage,"error");
              }else if(error.response.status === 500){
                this.showMessage(this.$t('MdNote.SavingNote'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
                  this.showMessage(this.$t('MdNote.SavingNote'),error.response.data.errorMessage,"error");
              }                
          /*if (error.response) {
            this.showMessage("Save Note",error.response.data.errorMessage,"error");
          } */
        });  
    },

    goToAfterSave() {
      this.$router.push({ name: this.pathAfterSave.name});
    },

    getAllValuesByControlId(ControlId){
       var temp = this.DesignerControls;
       var l = temp.length;
       var allvalues = [];
       for(var i = 0; i < l; i++){
         if(temp[i].controlID === ControlId){
            allvalues = temp[i].allValues;
            break;
          }
       }
       return allvalues;     
    },

    UploadAttachmentsNotes(){
      this.DataNote.attachments = [];
      for(var i = 0; i < this.FileRecords.length; i++){
      this.DataNote.attachments.push(
          {
            "docdocument":this.FileRecords[i].base64,
            "docfilename":this.FileRecords[i].name+this.FileRecords[i].extension,
            "doccomment": this.FileRecords[i].description,
            "new":this.FileRecords[i].new,
            "code":this.FileRecords[i].code
            
          }
        )
      } 
      
    },

    PrepareComponentsData(){
     var tmp = this.ArrayModel;
     //this.DataNote.therapies = [];
     var l = tmp.length;
     this.DataNote.details = [];
     for(var i = 0; i < l; i++){
        var type = tmp[i].Type;
        var ControlisChanged = tmp[i].Changed;
        if(type !== 'TIssImage' && type !== 'TIssImageBG'){
         var finalCode = "VM_"+tmp[i].ControlId;
          var obj = {
              controlId: tmp[i].ControlId,
              controlType: type,
              selectedValues : []     
          };
          if(type === 'TIssLabel'){
                 obj.objectContents = null;
                 obj.extension = null;
                 obj.contents = "";
                 obj.allValues = [];
                 obj.selectedValues = [];
          }
         else if(type === 'TIssSignatureCapture' || type === 'TIssImageEditor'){           
            if(this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode]){
                 obj.objectContents = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode].split(',')[1];
                 obj.extension = ".bmp";//this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode].Extension;
                 obj.contents = "";
                 obj.allValues = [];
                 obj.selectedValues = [];
            }else{
                 obj.objectContents = null;
                 obj.extension = null;
                 obj.contents = "";
                 obj.allValues = [];
                 obj.selectedValues = [];
            }
         
          }else if(type === 'TIssEdit' || type === 'TIssMemo' || type ==='TIssRichEdit' || type ==='TIssMaskEdit' || type ==='TIssHtmlEditor'){
            var datacontrol = this.ArrayModel.find(d => d[finalCode]);
           if(datacontrol !== undefined){
             obj.objectContents = null;
             obj.extension = null;
             obj.contents = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];
             obj.allValues = [];
             obj.selectedValues = [];
           }else{
             obj.objectContents = null;
             obj.extension = null;
             obj.contents = "";
             obj.allValues = [];
             obj.selectedValues = [];
           }
          }else if(type === 'TIssDateTimePicker'){
            var date = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];            
            if(!Array.isArray(date) && date){
            obj.objectContents = null;
            obj.extension = null;
            obj.contents = Utils.formatterDateToString(this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode]); 
            obj.allValues = [];
            obj.selectedValues = [];
            }else{
            obj.objectContents = null;
            obj.extension = null;
            obj.contents = "";
            obj.allValues = [];
            obj.selectedValues = [];
            }
          }else if(type === 'TIssCheckListBox'){
            var checklistbox = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];           
               obj.objectContents = null;
               obj.extension = null;
               obj.contents = "";
               obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId);
               obj.selectedValues = checklistbox;
          }
          else if(type === 'TIssComboBox'){
              var datacombo = this.ArrayModel.find(d => d[finalCode]);
             if(datacombo !== undefined){
               if(!Array.isArray(datacombo[finalCode])){
               var combo = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];           
               obj.objectContents = null;
               obj.extension = null;
               obj.contents = "";
               obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId);
               obj.selectedValues.push(combo);
               }else{
                 if(datacombo.length > 0){
                    var combobox = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];           
                    obj.objectContents = null;
                    obj.extension = null;
                    obj.contents = "";
                    obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId);
                    obj.selectedValues.push(combobox[0]);
                 }else{                  
                    obj.objectContents = null;
                    obj.extension = null;
                    obj.contents = "";
                    obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId);
                    obj.selectedValues = [];
                 }
               }
             }else{
               obj.objectContents = null;
               obj.extension = null;
               obj.contents = "";
               obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId);
               obj.selectedValues = [];
             }
          }
          else if(type === 'TIssCheckBox'){
             var checkbox = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];
                  obj.objectContents = null;
                  obj.extension = null;                  
                  obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId); 
                  obj.selectedValues = [];
                  if(checkbox.length > 0){
                      if(checkbox[0] === true)
                         obj.contents = "true";
                      else
                         obj.contents = "false";                   
                  } else {   
                       if(Array.isArray(checkbox)){  
                          obj.contents = "false";
                        }else{
                          obj.contents = checkbox?"true":"false";
                        }
                  }                 
          }
          else if(type === 'TIssRadioGroup'){
            var dataradio = this.ArrayModel.find(d => d[finalCode]);
             if(dataradio !== undefined){
               var radio = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];           
               var arr_radios = [];
               arr_radios.push(radio);
               obj.objectContents = null;
               obj.extension = null;
               obj.contents = "";
               obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId);
               obj.selectedValues = arr_radios;
             }else{
               obj.objectContents = null;
               obj.extension = null;
               obj.contents = "";
               obj.allValues = this.getAllValuesByControlId(tmp[i].ControlId);
               obj.selectedValues = [];
             }
          }

          else if(type === 'TfmWileyModalitytoObjective'){
            obj.treatmentPlan = {};
            let data = this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode];            
           
            let objTreatment = this.WileyModalitytoObjectivePrepare(data);           
            obj.treatmentPlan = objTreatment;
          }

          else if(type === 'TIssFormTable'){
            let tableData = this.ArrayModel.find(d => d[finalCode]);
            let rows = tableData['VM_'+tableData.ControlId]
           // let columns = this.getTableColumns(rows)
            let columns = this.getObjectColumns(tableData['Columns']);
            let tableRow = {rows: rows, columns: columns}
            if(rows != undefined){
               obj.objectContents = null;
               obj.extension = null;
               obj.contents = null;
               obj.allValues = null;
               obj.selectedValues = null;
               obj.table = tableRow
             }
          }
          if(this.Header.noteStatus !== 'New'){
            if(ControlisChanged){
              this.DataNote.details.push(obj);
            }            
          }else{
             this.DataNote.details.push(obj);
          }
         
        }
     }
     
     if(this.ObjectItems.ArrayVModel.length > 0) {
        this.DataNote.therapies = [];
         if(this.Header.noteStatus === 'New') {
            this.ObjectItems.ArrayVModel.forEach(element => {
                let therapiesItems = {};
                var Founded = this.FoundTherapy(element.code);
                if(Founded){
                  therapiesItems['therapycode'] = element.code;
                  therapiesItems['units'] = Number(element.units);
                  this.DataNote.therapies.push(therapiesItems);
                }
            });
         }else{
          this.ObjectItems.ArrayVModel.forEach(element => {
          let therapiesItems = {}; 
            if(element.code){
              therapiesItems['therapycode'] = element.code;
              therapiesItems['units'] = Number(element.units);
              this.DataNote.therapies.push(therapiesItems);
            }else{
              this.DataNote.therapies = [];
            }         
            
                      
        });
         }
     }
     if(this.CoTherapists.code){
      this.$set(this.DataNote.Header, 'Cotherapistcode', this.CoTherapists.code);
    }
     this.$set(this.DataNote.Header, 'Issigned', this.Header.isSigned);
     this.$set(this.DataNote.Header, 'IsCoSigned', this.Header.isCoSigned);
     this.$set(this.DataNote.Header, 'IsPendingCoSignature', this.Header.isPendingCoSignature);


     let SD = this.Header.signedDate ? moment(this.Header.signedDate).format('YYYY-MM-DDTHH:mm:ss'):null;     
     this.$set(this.DataNote.Header, 'SignedDate', SD);
     let CD = this.Header.coSignedDate ? moment(this.Header.coSignedDate).format('YYYY-MM-DDTHH:mm:ss'):null;    
     this.$set(this.DataNote.Header, 'CosignedDate', CD);

     if(this.Location === "SchedulerNotes"){
      this.DataNote.Header.ApptType = this.$attrs.model.appointmentTypeCode;
     }

    if(this.Header.customFrame === 'TFRMDOCUMENTNOTES'){
          this.UploadAttachmentsNotes();
      }

    },


    WileyModalitytoObjectivePrepare(data){
      let tmp = {
        noteHeaderId:this.Header.Id,
        interventionReviews:[],
        progressReviews:[]
      };     
      if(Object.entries(data).length !== 0){
        var l = data.problems.length;     
      for(var i = 0; i < l; i++){
        tmp.progressReviews.push({probRecId:data.problems[i].problem.probRecId,problemReview:data.problems[i].progressReviews.problemReview});
        for(var j = 0 ; j < data.problems[i].goals.length; j++){
          for(var k = 0; k < data.problems[i].goals[j].objectives.length; k++){            
            let dateAch = data.problems[i].goals[j].objectives[k].interventionReviews.dateAchieve;                   
            tmp.interventionReviews.push(
              {
                probRecId:data.problems[i].problem.probRecId,
                objRecId:data.problems[i].goals[j].objectives[k].objective.objRecId,
                dateAchieve:  dateAch ? moment(dateAch).format('YYYY-MM-DDTHH:mm:ss') : null,
                interventionNote:data.problems[i].goals[j].objectives[k].interventionReviews.interventionNote,
                therapyCode:data.TherapyCode ? data.TherapyCode : data.therapyCode,
                status:data.problems[i].goals[j].objectives[k].interventionReviews.statusModel !== null ? data.problems[i].goals[j].objectives[k].interventionReviews.statusModel.code : null
              }
            );               
          }
        }
      }
    }else{
      tmp = null;
    }
      return tmp;
    },

    populateTherapies(){
      var Founded = this.FoundTherapy(this.Header.transCode);
      if(this.Header.noteStatus == 'New') {
        let therapiesItems = {};
        if(Founded){
          therapiesItems['therapycode'] = this.Header.transCode         
          therapiesItems['units'] = this.Header.defaultUnit
          therapiesItems['name'] = this.Header.transCode
          this.DataNote.therapies.push(therapiesItems);
          this.ObjectItems.ArrayVModel = this.DataNote.therapies;
        }else{
            this.DataNote.therapies = [];
        }        
      }
    },

  FoundTherapy(code){     
      var arr = this.ObjectItems.ArrayValues;
      if(arr !== undefined){
         var l = arr.length;
      for(var i = 0; i < l; i++){
        if(arr[i].code === code){
          return true;
        }
      }
      return false;
      }
      return false;     
    },

    FoundTherapies(code){     
      var l = this.TherapiesBillingInfoList.length;
      for(var i = 0; i < l; i++){
        if(this.TherapiesBillingInfoList[i].code === code){
          return true;
        }
      }
      return false;     
    },

    FoundinListTherapies(code){
      var l = this.NotesTherapiesByNoteList.length;
      for(var i = 0; i<l; i++){
        if(this.NotesTherapiesByNoteList[i].code === code){
          return true;
        }
      }
      return false;
    },

    getTherapyByCode(code){
      var l = this.TherapiesBillingInfoList.length;
      var result = {};
      for(var i = 0; i < l; i++){
        if(this.TherapiesBillingInfoList[i].code === code){
          result = this.TherapiesBillingInfoList[i];
        }
      }
      return result;   
    },

    getTableColumns(rows){
      let columnsName = Object.keys(rows[0])
      let columns = []
      columnsName.forEach(element => {
        columns.push({key: element})
      });
      return columns
    },

  getObjectColumns(cols){
     // let columnsName = Object.keys(rows[0])
      let columns = []
      cols.forEach(element => {
        columns.push({key: element.key})
      });
      return columns
    },

    SetVoidNote(){
       if(this.Header.isVoid){
          this.SetReadOnlyAllControls();
        }
    },

    async getNoteOption(){
      this.NoteOptions = await this.getNoteOptions();   
    },

    getPosTime(Time){
      var arr = this.HoursList;
      var l = arr.length;
      for(var i = 0; i < l; i++){
        if(arr[i].value === Time){
          return i;
        }
      }
      return -1;
    },

   async validate_SignedDates(){
      let result = {status:"OK",description:""};
      if(this.Header.signedDate && !Utils.IsValidDateAndRange(this.Header.signedDate)){
        result.description = "Invalid Signed Date";
        result.status = "Error";        
      }
     else if(this.Header.coSignedDate && !Utils.IsValidDateAndRange(this.Header.coSignedDate)){
        result.description = "Invalid Signed Date";
        result.status = "Error";        
      }
      return result;
    },

    async SaveNote(closeOnSave){     
  //this.PrepareComponentsData();
      if(this.HoursList.length > 0){
         if(this.StartTime.value === '' || this.EndTime.value === ''){
          this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.MustProvideStartEndTime'),"error");        
          return;
        }
         if(this.StartTime && this.EndTime && this.StartTime.value === this.EndTime.value){
          this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.StartEndSame'),"error");          
            return;
        }
         if(this.StartTime && this.EndTime && this.getPosTime(this.EndTime.value) <  this.getPosTime(this.StartTime.value)){
          this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.EndTimeGreater'),"error");         
            return;
        }
      }     
      if(this.therapistcode === ''){
        this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.MustSelectTherapist'),"error");       
          return;
      }
      
      if((this.therapistcode.code !=='' && this.CoTherapists.code !== '') && ( this.therapistcode.code === this.CoTherapists.code)){
        this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.CotherapistSameTherapist'),"error");        
          return;
      }

      if(!this.NoteOptions.canSaveUnsigned && !this.Header.isSigned){
         this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.TherapistMustSign'),"error");        
           return;
      }
      else if((this.CoTherapists.code !== '' || this.CoTherapists.code === this.EmployeeCode)  && !this.Header.isCoSigned && this.Header.isPendingCoSignature){
        this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.CosignerMustSign'),"error");     
          return;
      }

      if(this.Header.isSigned && !this.Header.isVoid && this.Header.isBillable){        
           if(this.DataNote.therapies.length == 0) { // this is qyNotesTherapies.IsEmpty
            this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.MustSelectTherapy'),"error");        
            return;      
        }        
      }

      if(this.ServiceDate === ''){
         this.showMessage(this.$t('MdNote.SavingNote'),this.$t('MdNote.MustSelectService'),"error");        
           return;
      }
      
      if(this.validate_required()) {
          this.Load = true;

        let result = await this.validate_SignedDates();
        if(result.status === "OK"){
          this.PrepareComponentsData();
          this.SetVoidNote();
          if(this.Header.noteStatus === 'New'){
            await this.SaveHeader();
          // this.setNoteIsEditing(this.Id, false)
          }else{
            await this.UpdateNote();
          // this.setNoteIsEditing(this.Id, false)
          }
        }else{
          this.Load = false;
          this.showMessage(this.$t('MdNote.SavingNote'),result.description,"errorAutoHide");
        }

         if(closeOnSave){
           this.$emit('remove');
         }
			} else {
				this.showMessage(this.$t('MdNote.SavingNote'),this.$t('Msg.CheckRequiredFields'),"errorAutoHide");
			}
    },

    SetIcon(noteStatus){
      if(noteStatus == 'New'){								
				this.icon = 'ikon med-doc-new mr-2';
			}
			else if(noteStatus == 'Voided'){
        this.icon = 'ikon med-doc-cancelled mr-2';
			}
			else if(noteStatus == 'Pending Signature'){
        this.icon = 'ikon med-doc-pending-signature mr-2';								
			}
			else if(noteStatus == 'Pending Cosigner Signature'){
        this.icon = 'ikon med-doc-pending-signature mr-2';
			}else{
        this.icon = 'ikon med-doc-complete mr-2';								
			}
    },

    EmitNoteStatus(ObjNote,Status,frequency,DatesAndNames, activityId){
      //this.Header.therapistCode,this.Header.coTherapistCode
      ObjNote.NoteStatus = Status;
      ObjNote.frequency = frequency;
      ObjNote.DatesAndNames = DatesAndNames;
      ObjNote.activityId = activityId;

    
      EventBus.$emit('onChangeNoteStatus', ObjNote);


      this.DataNote.Header.SignedDate = this.Header.signedDate ? moment(this.Header.signedDate).format('MM/DD/YYYY hh:mm A') : null; 
      this.DataNote.Header.CosignedDate = this.Header.coSignedDate ? moment(this.Header.coSignedDate).format('MM/DD/YYYY hh:mm A') : null; 
    },

   async getNotesHeader(ver){
      var params = {
        "Notesid":this.Header.notesId,
        "Headerid": this.Header.Id,
        "IgnoreVoidStatus": this.IsWorkFlow ? true : false,
        "IsGroupNote" : this.IsGroupNote ? true : false,
        "GroupCreationId" : this.IsGroupNote ? this.Header.groupCreationId : null,
        "IsVoid": this.CopyIsVoid,
        "Ver":ver,
      };
     await ClinicalService.getNotesHeaderListing(params)
        .then((response) => { 
          if(response.data && response.data.length > 0){   
            this.itemsNotesHeader = response.data[0];
            this.Header.coSignedDate =  Utils.formatterDateTimeToString(new Date(this.itemsNotesHeader.cosigneddate));
            this.Header.coTherapistCode =  this.itemsNotesHeader.cotherapistcode;
            this.Header.endTime =  this.itemsNotesHeader.endTime;
            this.Header.FacilityCode =  this.itemsNotesHeader.facilityCode;
            //this.Header.Id =  this.itemsNotesHeader.headerid;
            this.Header.isBillable =  this.itemsNotesHeader.isbillable;
            this.Header.isCoSigned =  this.itemsNotesHeader.isCoSigned;
            this.Header.isSigned =  this.itemsNotesHeader.issigned;
            this.Header.isVoid =  this.itemsNotesHeader.isvoid;
            this.Header.notesId =  this.itemsNotesHeader.notesid;
            this.Header.serviceDate =  this.itemsNotesHeader.servicedate;
            this.ServiceDate = Utils.formatterDate(this.Header.serviceDate);
            this.Header.signedDate =   Utils.formatterDateTimeToString(new Date(this.itemsNotesHeader.signeddate));
            this.Header.startTime =  this.itemsNotesHeader.startTime;
            this.Header.therapistCode =  this.itemsNotesHeader.therapistcode; 
            this.Header.noteStatus = this.itemsNotesHeader.noteStatus;
            this.Header.chartno = this.itemsNotesHeader.chartno;
            this.Header.isPendingCoSignature = this.itemsNotesHeader.isPendingCoSignature;
            this.Header.isPendingSignature = this.itemsNotesHeader.isPendingSignature;
            this.Header.noteTime = this.itemsNotesHeader.noteTime;
            this.Header.notesContents = this.itemsNotesHeader.notescontents;
            this.Header.therapyCode = this.itemsNotesHeader.transCode;
            this.Header.transCode = this.itemsNotesHeader.transCode;
            this.Header.transDate = this.itemsNotesHeader.transdate;
            this.Header.groupCreationId = this.itemsNotesHeader.groupCreationId;
            this.CopyIsVoid = this.itemsNotesHeader.isvoid;  
            
            this.SetIcon(this.Header.noteStatus);           

            let DatesAndNames = {
              isSigned:this.itemsNotesHeader.issigned,
              isCoSigned:this.itemsNotesHeader.isCoSigned,
              therapistName:this.itemsNotesHeader.therapistName,
              cotherapistName:this.itemsNotesHeader.cotherapistName,
              signeddate:this.itemsNotesHeader.signeddate ? moment(this.itemsNotesHeader.signeddate).format("ddd, ll"):null,
              cosigneddate:this.itemsNotesHeader.cosigneddate ? moment(this.itemsNotesHeader.cosigneddate).format("ddd, ll"):null,
              therapistCode:this.Header.therapistCode,
              coTherapistCode:this.Header.coTherapistCode,
            }
                
            this.EmitNoteStatus(params,this.Header.noteStatus,this.Header.frequency,DatesAndNames,this.Header.activityId ? this.Header.activityId : null,this.Header.therapistCode,this.Header.coTherapistCode);

            if(this.Header.noteStatus != 'New' && this.Header.customFrame != 'TFRMDOCUMENTNOTES'){
              this.showPrint = true;
            }

            if (this.Header.IsCommonPart) 
              EventBus.$emit('updateNoteStatusOnSave',this.Header.noteStatus);

            this.IsShowCosignerTab = this.ShowCosignerTab();
            if(this.IsShowCosignerTab){
              this.getCoTherapists();
            }

          }else{
            this.icon = 'ikon med-doc-new mr-2';
          }
         
        })
        .catch(error => {
        if(error.response.status === 404){
        return;
        }
        if (error.response && 404 !== error.response.status) {
            this.showMessage(this.$t('MdNote.GetNotesHeader'),error,"error");
          } 
        });  
      },
      getTherapists(){
        TherapistService.getAllTherapist(this.Header.therapistCode)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.TherapistsList = response.data;
              this.SetTherapists();
              this.VisibleHeader = this.VisibleNoteHeader === true ? true : false
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetTherapists'),error,"error");
            } 
          });  
      },

      async getCoTherapists(){
        if(this.Header){
          if(this.Header.allowCosignNotesDoneByCosigners || this.Header.isPendingCoSignature || this.Header.isCoSigned){
              await TherapistService.getAllCOTherapist(this.Header.coTherapistCode)
              .then((response) => { 
              if(response.data && response.data.length > 0){   
              this.CoTherapistsList = response.data;
              var arr = this.CoTherapistsList;
              for(var i = 0; i<arr.length; i++){
                arr[i].fullName = arr[i].firstName+" "+arr[i].lastName;
              }
              this.CoTherapistsList = arr;        
              this.SetCoTherapists();
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetCoTherapists'),error,"error");
            } 
          });  
          }
        }
      },

      setFacilityCode(){  
        if(this.Header !== null && this.Header.FacilityCode !== null && this.Header.FacilityCode !== ""){                
                PatientService.getFacilities(this.Header.FacilityCode)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.FacilityDescription = response.data[0].company; 
                        this.$set(this.DataNote.Header, 'FacilityCode', response.data[0].code);
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
              });  
            }
      },

  populateDataNoteTherapies(arr){
    this.DataNote.therapies = [];
    var l = arr.length;   
    for(var i = 0;  i<l; i++){     
        this.DataNote.therapies.push({Therapycode:arr[i].code,units:arr[i].units});     
    }
  },

   async NotesTherapiesByNote(){   
        if(this.Header.Id){
              let headerId = this.Header.Id        
              await ClinicalService.NotesTherapiesByNote(headerId, this.Header.groupCreationId, this.IsGroupNote ? true : false, this.ProgramCode)
              .then((response) => { 
                if(response.data)
                {  
                  this.NotesTherapiesByNoteList = response.data;  
                               
                 // EventBus.$emit('OnReceivedTherapyByNote',this.NotesTherapiesByNoteList);

                  if(this.Header.noteStatus === "New" && this.NotesTherapiesByNoteList.length == 0)
                  {
                    if(this.Header.transCode){
                      var Founded = this.FoundTherapies(this.Header.transCode);
                      if(Founded){
                        var result = this.getTherapyByCode(this.Header.transCode);
                       
                        this.NotesTherapiesByNoteList.push({code:result.code,units:this.Header.defaultUnit,name:result.name});
                        this.populateDataNoteTherapies(this.NotesTherapiesByNoteList);
                      }
                    }
                  }
                  else
                  {
                    this.populateDataNoteTherapies(this.NotesTherapiesByNoteList);
                  }
                  this.NotesTherapiesByNoteListCopy = [...this.NotesTherapiesByNoteList]; 
                  
                }

               // if(this.Header.noteStatus === 'New'){
                //    this.ObjectItems.ArrayVModel.push({"code":this.Header.transCode,"units":this.Header.defaultUnit});
               // }else{
                  //this.$set(this.ObjectItems, 'ArrayVModel', this.NotesTherapiesByNoteList);
                  //this.VisibleTableTherapies = true;
                 // this.Load = false;
               // }
          })
          .catch(error => {           
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetTherapies'),error,"error");
            }
          });
        }
      },

    async GetTherapiesHeaderBillingInfo(){
      if(this.Location === "SchedulerNotes"){      
        await ClinicalService.getTherapiesHeaderBillingInfoFromScheduler(this.Header.transCode, this.ProgramCode, this.$attrs.model.appointmentTypeCode)
              .then((response) => { 
                if(response.data){   
                  this.TherapiesBillingInfoList = response.data;    
                  this.TherapiesBillingInfoListCopy = response.data;

                  this.TherapiesBillingInfoList.forEach(element => {
                      element.name =  element.name +" "+element.code;
                  });	

                  this.$set(this.ObjectItems, 'ArrayValues', this.TherapiesBillingInfoList);
                }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetTherapiesHeader'),error,"error");
            }
          }); 
      }
      else if(this.IsGroupNote) {      
        await ClinicalService.getTherapiesHeaderBillingInfoFromGroupNotes(this.ProgramCode)
              .then((response) => { 
                if(response.data){   
                  this.TherapiesBillingInfoList = response.data;
                  this.TherapiesBillingInfoListCopy = response.data;    

                  this.TherapiesBillingInfoList.forEach(element => {
                      element.name =  element.name +" "+element.code;
                  });	

                  this.$set(this.ObjectItems, 'ArrayValues', this.TherapiesBillingInfoList);
                }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetTherapiesHeader'),error,"error");
            }
          }); 
      }
      else{
        await ClinicalService.getTherapiesHeaderBillingInfo(this.ProgramCode,this.CategoryCode)
              .then((response) => { 
                if(response.data){   
                  this.TherapiesBillingInfoList = response.data;
                  this.TherapiesBillingInfoListCopy = response.data;    

                  this.TherapiesBillingInfoList.forEach(element => {
                      element.name =  element.name +" "+element.code;
                  });	

                 // this.$set(this.ObjectItems, 'ArrayValues', this.TherapiesBillingInfoList);
                }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetTherapiesHeader'),error,"error");
            }
          });   
      }  
      },


      getStartAndEndTime(){
        GlobalService.getHours(this.Header.notesId)
          .then((response) => {            
            if(response.data){
             this.HoursList = response.data;         

             this.HoursList.forEach(element => {              
                element.text =  element.text.replaceAll('.','');
                element.value = element.text;
                var new_startDate= new Date();
                var date_resultend = moment(new_startDate).format('YYYY/MM/DD');
                var InternationalTimeEnd =  new Date(date_resultend+', '+element.value);
                element.text = moment(InternationalTimeEnd).format('hh:mm a');
                element.value = moment(InternationalTimeEnd).format('hh:mm a');
              });		

            }
          
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdNote.GetHours'),error,"error");
            } 
          });  
      },

      ChangeDropDownVersions(item){
        this.VersionModel =  item ? item : {code:0,description:"Current"}; 
        this.Load = true;
        this.getNotesHeader(this.VersionModel.code);
        this.getControlsAndValues(this.VersionModel.code);
        
        if(this.VersionModel.code > 0){
          this.NoteIsReadOnly = true;         
        }else{
          this.NoteIsReadOnly = false;
        }
        this.showPrint = true;
      },

      ChangeDropDownTherapist(value){ 
        if(value){
          this.therapistcode = value;  
          this.$set(this.DataNote.Header, 'Therapistcode', value.code);    
          this.Header.isSigned = false;    
        }else{
          this.therapistcode = {
            "code": "",
            "firstName": "",
            "fullName": "",
            "lastName": "",
          }
          this.$set(this.DataNote.Header, 'Therapistcode', "");
          this.Header.isSigned = false;
        } 
         //this.Header.isSigned = false;
      },

      ChangeDropDownStartTime(e){
        this.StartTime = e;      
        var new_startDate = new Date();
       
         var date_resultstart = moment(new_startDate).format('YYYY-MM-DD');
         var start = Utils.convertTo24Hour(date_resultstart + ' ' + e.value);
        this.$set(this.DataNote.Header, 'StartTimeString', this.StartTime.value);
        var InternationalTimeStart =  new Date(date_resultstart+'T'+start);
        this.$set(this.DataNote.Header, 'StartTime', moment(InternationalTimeStart).format());
      },

      ChangeDropDownEndTime(e){
        this.EndTime = e;
        var new_startDate= new Date();
        var date_resultend = moment(new_startDate).format('YYYY-MM-DD');
        this.$set(this.DataNote.Header, 'EndTimeString', this.EndTime.value);
        var end = Utils.convertTo24Hour(date_resultend + ' ' + e.value);

        var InternationalTimeEnd =  new Date(date_resultend+'T'+end);
        this.$set(this.DataNote.Header, 'EndTime', moment(InternationalTimeEnd).format());
      },

      ChangeDropDownTherapies(value){
        this.itemsNotesHeader.transCode = value ? value.value : '';
      },

      ChangeDropDownVoid(value){
        this.itemsNotesHeader.notescontents = value ? value.value : '';
      },

      ChangeDropDownCoTherapist(value) {
         if(value){
           this.CoTherapists = value;
           this.$set(this.DataNote.Header, 'Cotherapistcode', value.code);
         }else{
           this.CoTherapists = {
              "code": "",
              "firstName": "",
              "fullName": "",
              "lastName": "",
           };
           this.$set(this.DataNote.Header, 'Cotherapistcode', "");
         }
        this.Header.isCoSigned = false;
        this.Header.isPendingCoSignature = true;
      },

      formatterDate(value){
        return Utils.formatterDate(value);
      },

      onCodeFacilityOk(result){
        this.headersChanged = true;
        this.itemsNotesHeader.facilityCode = result.code;
        this.FacilityCode = result.code;
        this.FacilityDescription = result.description;
        this.$set(this.DataNote.Header, 'FacilityCode', result.code);
      },

      onCodePosOk(result){
        this.itemsNotesHeader.poscode = result.code;
      },

      ChangeServiceDate(e){  
        this.headersChanged = true;    
        this.$set(this.DataNote.Header, 'Servicedate', e);
      },

      ChangeSignedOn(){
        this.headersChanged = true; 
      },

      ChangeCoSignedOn(){
        this.headersChanged = true;
      },

      onSignPassword(value) { 
        if(value === false && this.Header.isSigned === true){
          this.headersChanged = true;
        } 

        this.TypePassWord = 'Sign';
        this.UserIdSelected = value ? this.therapistcode.code : '';  
        this.showSignPassword = value;        
        this.Header.isSigned = value;
        this.$set(this.DataNote.Header, 'Issigned', value);

        
      },

      OnclickPrint(){
        this.isReportVisible = true
      },

      onReasonBtn(){
        this.showReason = true;
      },

      onReason(value){
        this.showReason = value;
        this.Header.isVoid = value;       
      },
      onChangeBillable(value){
        if(value === false && this.Header.isBillable === true){
          this.headersChanged = true;
        } 
        if(value === true && this.Header.isBillable === false){
          this.headersChanged = true;
        } 
        this.Header.isBillable = value;
        this.$set(this.DataNote.Header, 'Isbillable', value);
      },
      onChangeCoSigned(value){

        if(value === false && this.Header.isCoSigned === true){
          this.headersChanged = true;
        } 

        this.Header.isCoSigned = value;
        this.TypePassWord = 'Cosign';
        this.UserIdSelected = value ? this.CoTherapists.code : '';        
        this.showSignPassword = value;      
        this.$set(this.DataNote.Header, 'IsCoSigned', value);
      },
      
      
      getDateTime(isTherapist){
        this.Load = true;
        GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                 let _date = moment(response.data).format('MM/DD/YYYY hh:mm A'); 
                 isTherapist ? this.$set(this.Header, 'signedDate', _date) : this.$set(this.Header, 'coSignedDate', _date); 
                 this.Load = false;                
               }  
               this.Load = false; 
         })
         .catch(error => {
              this.Load = false; 
            if (error.response) {
               this.showMessage("get DateTime",error,"error");
            }
         }); 
      },


      SetTherapists(){     
        if(this.Header.therapistCode){
          var l = this.TherapistsList.length;
          for(var i = 0; i < l; i++){
            if(this.TherapistsList[i].code.toLowerCase() == this.Header.therapistCode.toLowerCase()){
              this.therapistcode = this.TherapistsList[i];
            }
          }
        }      
      },

      /*SignandSave(){   
        this.TypePassWord = 'Sign';
        this.UserIdSelected = this.therapistcode ? this.therapistcode.code : '';  
        this.showSignPassword = true;        
        this.Header.isSigned = true;
        this.$set(this.DataNote.Header, 'Issigned', true);
      },

      CoSignandSave(){
        this.Header.isCoSigned = true;
        this.TypePassWord = 'Cosign';
        this.UserIdSelected = this.CoTherapists ? this.CoTherapists.code : '';        
        this.showSignPassword = true;      
        this.$set(this.DataNote.Header, 'IsCoSigned', true);        
      },*/

      SetCoTherapists(){
        if(this.Header.coTherapistCode){          
          var ll = this.CoTherapistsList.length;
          for(var j = 0; j < ll; j++){
            if(this.CoTherapistsList[j].code.toLowerCase() == this.Header.coTherapistCode.toLowerCase()){
              this.CoTherapists = this.CoTherapistsList[j];
            }
          }
        }
      },


      showModalSignandSave(){
        this.showSignandSave = true;
      },

      showModalCoSignandSave(){
        this.showCoSignandSave = true;
      },


      onModalSignandSaveResult(result){

        if(result.status !== true) {
            this.isReadOnlyDateSign = true;
            this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");
            this.Header.isSigned = false;
            this.$set(this.DataNote.Header, 'Issigned', false);
          }else{
            this.headersChanged = true;
            this.Header.isSigned = true;
            this.$set(this.DataNote.Header, 'Issigned', true);          
            this.getDateTime(true);
            this.isReadOnlyDateSign = this.NoteOptions.allowChangingSignedDate === true ? false : true;
            this.SaveNote(false);
         
        
           
          }
        
        this.showSignandSave = false;
      },

      onModalCoSignandSaveResult(result){
        if(result.status !== true) {
            this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");
            this.Header.isCoSigned = false;
            this.$set(this.DataNote.Header, 'IsCoSigned', false);
          }else{
            this.Header.isCoSigned = true;
            this.headersChanged = true;
            this.Header.coTherapistCode = result.CoTherapist;
            this.$set(this.DataNote.Header, 'Cotherapistcode', result.CoTherapist);
            this.$set(this.DataNote.Header, 'IsCoSigned', true);
            this.getDateTime(false);
            this.isReadOnlyDateCoSign = this.NoteOptions.allowChangingSignedDate === true ? false : true;
            this.SaveNote(false);
          }
        this.showCoSignandSave = false;
      },

      onModalPasswordResult(result) {        
        if(result.type === 'Sign'){
          if(result.status !== true) {
            this.isReadOnlyDateSign = true;
            this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");
            this.Header.isSigned = false;
            this.$set(this.DataNote.Header, 'Issigned', false);
          }else{
            this.headersChanged = true;
            this.Header.isSigned = true;
            this.$set(this.DataNote.Header, 'Issigned', true);          
            this.getDateTime(true);
            this.isReadOnlyDateSign = this.NoteOptions.allowChangingSignedDate === true ? false : true;
            this.SaveNote(false);
          }
        }

         if(result.type === 'Cosign'){
          if(result.status !== true) {
            this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");
            this.Header.isCoSigned = false;
            this.$set(this.DataNote.Header, 'IsCoSigned', false);
          }else{
            this.Header.isCoSigned = true;
            this.headersChanged = true;
            this.$set(this.DataNote.Header, 'IsCoSigned', true);
            this.getDateTime(false);
            this.isReadOnlyDateCoSign = this.NoteOptions.allowChangingSignedDate === true ? false : true;
            this.SaveNote(false);
          }
        }
        this.showSignPassword = false;      
      },

      LoadControls(){
        if (!this.ShowNote)
          this.getControlsAndValues('');
        this.DesignerControls = [...this.DesignerControlsCopy];
        this.Load = false;
        this.ShowNote = true;
        this.showPrint = (this.Header.noteStatus != 'New' && this.ShowNote && this.Header.customFrame != 'TFRMDOCUMENTNOTES')
      },

      PopulateTherapist(){
        this.Load = true;
        TherapistService.getAllTherapistReport()
            .then((response) => {      
               if (response.data && response.data.length > 0) {
                  this.TherapistsList = response.data;
                  this.Load = false;                
               }  
               this.Load = false; 
         })
         .catch(error => {
              this.Load = false; 
            if (error.response) {
               this.showMessage("get Therapist List",error,"error");
            }
         }); 
      },

      onModalPasswordResultSuperVisor(result){      
      if(result.Event === 'Ok'){   
        if(result.status === true) {
          this.Load = true;
          this.EnabledEdit = true;
          this.DesignerControls = [];
            if(!this.Header.isSigned){
              this.PopulateTherapist();
            }          
          setTimeout(() => 
            this.LoadControls()
                          , 50);  
      }else{
        this.EnabledEdit = false;           
        this.showMessage(this.$t('MdNote.VerifyPassword'),this.$t('Msg.InvalidSupervisorPass'),"error");
      }
    }
     
        this.showSignSuperVisor = false;
    },

      onModalReasonResult(result){
        this.Header.isVoid = result;
        this.$set(this.DataNote.Header, 'Isvoid', result);
        this.showReason = false;
      },
      onModalReasonOK(result){
        if(result.code){
           this.Header.isVoid = true;
           this.changesPending = true;
           this.headersChanged = true;
           this.CopyIsVoid = true;
           this.ReasonSelected = result; 
           this.Header.notesContents =  this.ReasonSelected.name;         
           this.$set(this.DataNote.Header, 'notesContents',  this.ReasonSelected.name);
           this.$set(this.DataNote.Header, 'Isvoid', true);
           EventBus.$emit('setVoidGroupNote',this.Header.patientCode)
        }else{
           this.Header.isVoid = false; 
           this.showReason = false;   
           this.CopyIsVoid = false;       
           this.$set(this.DataNote.Header, 'Isvoid', false);
           this.Header.notesContents = "";   
           this.$set(this.DataNote.Header, 'notesContents',  "");
        }
       
      },
      ShowCosignerTab(){
        if (this.IsGroupNote){
          if ( this.Header.isSigned && (this.Header.allowCosignNotesDoneByCosigners || this.Header.isPendingCoSignature || this.Header.isCoSigned) ){
              return true;
          }
          else{
            return false;
          }
        }
        else{
          if(this.Header.noteStatus === 'New' || ( this.Header.isSigned === false && this.Header.isCoSigned === false)){           
            return false;
          }
          else{
            if( this.Header.allowCosignNotesDoneByCosigners || this.Header.isPendingCoSignature || this.Header.isCoSigned){              
              return true;
            }
          }
        }
      },

      ShowCosignAndSave(){
        if (this.IsGroupNote){
          if ( this.Header.isSigned && (this.Header.allowCosignNotesDoneByCosigners || this.Header.isPendingCoSignature) && !this.Header.isCoSigned && this.Header.IsCommonPart){
              return true;
          }
          else{
            return false;
          }
        }
        else{
          if(this.Header.noteStatus === 'New' || ( this.Header.isSigned === false && this.Header.isCoSigned === false)){           
            return false;
          }
          else{
            if( (this.Header.allowCosignNotesDoneByCosigners || this.Header.isPendingCoSignature) && !this.Header.isCoSigned){              
              return true;
            }
          }
        }
      },      

      validateFileName(){
        var count = 0;
        var l = this.FileRecords.length;
        for(var i = 0; i<l; i++){
          if(this.FileRecords[i].name.length === 0){
              count++;
          }
        }
        return count;
      },

      validate_required() {
        let requiredCount = 0
        if( this.ArrayModel && this.ArrayModel.length > 0 && this.Header.isSigned && !this.Header.isVoid) {
            this.ArrayModel.forEach(component => {
              if(component.Required) {

                if(component.Type == 'TIssSignatureCapture'){               
                 /* component['VM_'+component.ControlId] && component['VM_'+component.ControlId] != undefined && component['VM_'+component.ControlId] != null
                    && */component['VM_'+component.ControlId] == "" ? this.$refs['Ref_'+component.ControlId][0].setValid(true)  : 
                      this.$refs['Ref_'+component.ControlId][0].setValid(false)
                      requiredCount = component['VM_'+component.ControlId] == "" ? requiredCount+1 : requiredCount
                }

                else if(component.Type == 'TIssCheckListBox'){                 
                    /*component['VM_'+component.ControlId] && component['VM_'+component.ControlId] != undefined && component['VM_'+component.ControlId] != null
                    && */ this.$refs['Ref_'+component.ControlId][0].selected.length == 0 ? this.$refs['Ref_'+component.ControlId][0].setValid(true)  : 
                      this.$refs['Ref_'+component.ControlId][0].setValid(false)

                      requiredCount = component['VM_'+component.ControlId].length == 0 ? requiredCount+1 : requiredCount
                }
               else if(component.Type == 'TIssEdit' || component.Type == 'TIssMemo' || component.Type == 'TIssMaskEdit' ||                  
                   component.Type == 'TIssComboBox' || component.Type == 'TIssDateTimePicker' ||
                   component.Type == 'TIssRadioGroup' || component.Type == 'TIssRichEdit' || component.Type == 'TIssHtmlEditor') {

                 if(component['VM_'+component.ControlId] != undefined && component['VM_'+component.ControlId] != null){
                  component['VM_'+component.ControlId].trim() == "" && component['VM_'+component.ControlId].trim().length == 0 ? 
                      this.$refs['Ref_'+component.ControlId][0].setValid(true)  : 
                      this.$refs['Ref_'+component.ControlId][0].setValid(false);
                      requiredCount = component['VM_'+component.ControlId].trim() !== "" && component['VM_'+component.ControlId].length > 0 ? requiredCount : requiredCount+1
                 }else{
                  requiredCount = requiredCount +1;
                  this.$refs['Ref_'+component.ControlId][0].setValid(true);
                 }                    
                } 
                else if(component.Type == 'TIssCheckBox') {
                  component['VM_'+component.ControlId] == false ? 
                    this.$refs['Ref_'+component.ControlId][0].setValid(false)  : 
                    this.$refs['Ref_'+component.ControlId][0].setValid(true)
                  requiredCount = component['VM_'+component.ControlId] == false ? requiredCount+1 : requiredCount
                }
              }
              if(component.Type == 'TIssFormTable') {
                  let tableRequired = false;
                  let columnsRequired = false;
                  /*if(component.Required){
                    tableRequired = this.isTableEmpty(component['VM_'+component.ControlId])
                    requiredCount = tableRequired ? requiredCount+1 : requiredCount
                  }*/
                  tableRequired = component.Required ? true : false;
                  columnsRequired = this.isRequiredColumns(component.ControlId) ? true : false;
                //  this.isColumnEmpty(component['VM_'+component.ControlId]);
                  requiredCount = (tableRequired && this.isTableEmpty(component['VM_'+component.ControlId])) || (columnsRequired && this.AllCoumnsRequiredHasValues(component['VM_'+component.ControlId],component.ControlId)) ? requiredCount+1 : requiredCount
                 
                  //if(this.isRequiredColumns(component.ControlId)) {
                    //This is On hold.
                    //Don't delete this!!!
                    //https://github.com/IvetC/MedEZVue/issues/282

                    //tableRequired = this.isRequiredColumns(component.ControlId)
                   // requiredCount = tableRequired ? requiredCount+1 : requiredCount
                //  }
                  var RequiredTableAndEmpty = tableRequired && this.isTableEmpty(component['VM_'+component.ControlId]);
                  var RequiredColumnAndEmpty = columnsRequired && this.AllCoumnsRequiredHasValues(component['VM_'+component.ControlId],component.ControlId);
                  //var RequiredColumnAndEmpty = columnsRequired && c;

                  if(RequiredTableAndEmpty){
                    this.$refs['Ref_'+component.ControlId][0].setValid(true,"Table");
                  }else{
                     this.$refs['Ref_'+component.ControlId][0].setValid(false,"Table");
                  }


                  if(RequiredColumnAndEmpty){
                      this.$refs['Ref_'+component.ControlId][0].setValid(true,"Column");
                  }else{
                      this.$refs['Ref_'+component.ControlId][0].setValid(false,"Column");
                  }

                  //this.$refs['Ref_'+component.ControlId][0].setValid(/*tableRequired || columnsRequired*/);
              }
            });
        }

        if(this.Header.customFrame === 'TFRMDOCUMENTNOTES'){
          requiredCount = this.validateFileName();
        }

        return requiredCount > 0 ? false : true
      },

      AllCoumnsRequiredHasValues(tableValues,ControlId){
        var generalCount = 0;
        if(tableValues.length > 0){
          var countColumns = 0;
          let fields = this.getRequiredColumns(ControlId);
            for(var i = 0; i < tableValues.length; i++){   
               countColumns = 0;       
            for(var j = 0; j < fields.length; j++){
              if(tableValues[i][fields[j]] != undefined && tableValues[i][fields[j]] != null){
                if(!Number.isInteger(tableValues[i][fields[j]])){
                  countColumns = tableValues[i][fields[j]].trim() === "" || tableValues[i][fields[j]].trim().length === 0 ? countColumns+1 : countColumns;
                }  
              }else{
                countColumns++;
              }                           
             } 
             if(countColumns === fields.length){
             // return false;
              generalCount++;
             }      
          }
          if(generalCount === 0){
            return false;
          }

        }
        return true;
      },

      isColumnEmpty(tableValues,ControlId){
        if(tableValues.length > 0){
          let fields = Object.keys(tableValues[0]);
          var count = 0;
          for(var i = 0; i < tableValues.length; i++){
            count = 0;
            for(var j = 0; j < fields.length; j++){
              if(fields[j] !== 'RecID' && !this.getRequiredColumns(ControlId).includes(fields[j])){
                if(tableValues[i][fields[j]] != undefined && tableValues[i][fields[j]] != null){
                  if(!Number.isInteger(tableValues[i][fields[j]])){
                    count = tableValues[i][fields[j]].trim() == "" || tableValues[i][fields[j]].trim().length === 0 ? count+1 : count;
                  }    
                } 
              }              
            }
            if(this.AllCoumnsRequiredHasValues(tableValues,ControlId)){
              count = 0;
            }
            if(count > 0){
              return true;
            }
          }
        
          /*fields.forEach(element => {
            if(element && element !== 'RecID' && tableValues[0][element] != undefined){
              fieldsBusy = tableValues[0][element].length > 0 ? fieldsBusy+1 : fieldsBusy         
            }
          });*/
         // isEmpty = fieldsBusy > 0 ? false : true
        }
        return false;
      },

      isTableEmpty(tableValues){       
        let isEmpty = false
        if(tableValues && tableValues.length == 0) {
          isEmpty = true
        }
        else if(tableValues && tableValues.length == 1){
          let fields = Object.keys(tableValues[0])
          let fieldsBusy = 0
          fields.forEach(element => {
            if(element && element !== 'RecID' && tableValues[0][element] != undefined && tableValues[0][element] != null){             
             if(!Number.isInteger(tableValues[0][element])){
              fieldsBusy = tableValues[0][element].trim() == "" || tableValues[0][element].length === 0 ? fieldsBusy+1 : fieldsBusy  
             }
                     
            }
          });
          isEmpty = fieldsBusy === fields.length-1 ? true : false;
        }
        return isEmpty
      },

      //This is On hold.
      //Don't delete this method!!!
      //https://github.com/IvetC/MedEZVue/issues/282

       isRequiredColumns(controlID){
         let requiredColumns = 0
         this.DesignerControls.forEach(element => {
           if(element.controlID == controlID){
             element.table.columns.forEach(column => {
              requiredColumns = column.isRequired ? requiredColumns+1 : requiredColumns
            });
          }
       });
       return requiredColumns > 0 ? true : false
     },

      getRequiredColumns(controlID){
      var arr = [];
         this.DesignerControls.forEach(element => {
           if(element.controlID == controlID){
             element.table.columns.forEach(column => {
                if(column.isRequired){
                    arr.push(column.key);
                }
            });
          }
       });
       return arr;
     },

      isComponentsChanged(){
        let componentsChanged = 0
        this.ArrayModel.forEach(element => {
          componentsChanged = element.Changed && element.NoteId == this.Id ? componentsChanged + 1 : componentsChanged
        });       
        return componentsChanged > 0 ? true : false
      },

      setNoteIsEditing(NoteId,isChanged){
        var NoteInfo = {
         "NoteId":NoteId,
         "Status":isChanged
        }
        EventBus.$emit('onNoteIsEditing', NoteInfo);
        if(!isChanged){
          this.ArrayModel.map(function(component){
          if(component.Changed == true && component.NoteId == NoteId){
            component.Changed = false;
          }
            return component;
          });
          this.headersChanged = false;
          this.DocumentListChanged = false;
        }
        if(NoteId === this.Id){
          this.changesPending = isChanged
          this.showPrint = false
        }
      },

      EditTherapy(code,obj){
        var l = this.NotesTherapiesByNoteList.length;
        for(var i = 0; i < l; i++){
          if(this.NotesTherapiesByNoteList[i].code === code){
            this.NotesTherapiesByNoteList[i].code = obj.code;
            this.NotesTherapiesByNoteList[i].name = obj.name;
            this.NotesTherapiesByNoteList[i].units = obj.units;
          }
        } 
        this.NotesTherapiesByNoteListCopy = [...this.NotesTherapiesByNoteList];       
        this.populateDataNoteTherapies(this.NotesTherapiesByNoteList);
      },


      closeRepWindow() {
        this.isReportVisible = false
      },

      getReportTitle() {
        let reportTitle = `${this.NOTE.length > 50 ? this.NOTE.substring(0,50) + '...' : this.NOTE} - ${this.NotePatientInfo.firstName} ${this.NotePatientInfo.lastName}`
        reportTitle = reportTitle.length + this.NotePatientInfo.patientCode.length <= 100 ? `${reportTitle} - ${this.NotePatientInfo.patientCode}` : `${reportTitle}`
        return reportTitle
      },

      populateDataNoteWorkflow() {
        this.$set(this.DataNote.workflow, 'incidentID', this.IncidentID);
        this.$set(this.DataNote.workflow, 'workflowRecID', this.Header.workflowRecID);
        this.$set(this.DataNote.workflow, 'processRecID', this.Header.processRecID);
        this.$set(this.DataNote.workflow, 'actionRecID', this.Header.actionRecID);
      },

      showIWFSummary() {
        this.isShowingIncident ? EventBus.$emit('showSummary',false) : EventBus.$emit('showSummary',true)
        this.isShowingIncident = this.isShowingIncident ? false : true
        this.incidentLabel = this.isShowingIncident ? this.$t('MdNote.hInci') : this.$t('MdNote.vInci')
      },

    FindTherapy(code){     
      for(var i = 0; i < this.NotesTherapiesByNoteList.length; i++){
        if(this.NotesTherapiesByNoteList[i].code === code){
          return true;   
        }
      }
      return false;
    },

    popTherapies(code) {
      let newList = []

      if (code) {       
        let item = this.TherapiesBillingInfoListCopy.find(it => it.code === code)       
        this.TherapiesBillingInfoList.push(item)
      } else {
        this.TherapiesBillingInfoList.forEach(el => {
        let therapyIndex = this.NotesTherapiesByNoteList.find(it => it.code === el.code)
        if (!therapyIndex) {
          newList.push(el)
        }
      });
      this.TherapiesBillingInfoList = newList
      }
    },

    MatchSuperVisor(){
      return this.NoteOptions.showVersioOnlyForSupervisors && this.isSupervisor;
    },

    getVersions(){
      ClinicalService.getNotesHeaderVers(this.Header.Id,this.Header.notesId)
                .then((response) => {
                    if(response.status == '200'){
                       this.VersionList = response.data;
                       this.VisibleVersion = this.VersionList.length <= 1 ? false : true;                         
                    }                             
                })
                .catch((error) => {                     
                    if (!error.response) {
                   this.showMessage(this.$t('MdNote.GetNotesHeader'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('MdNote.GetNotesHeader'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('MdNote.GetNotesHeader'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('MdNote.GetNotesHeader'),error.response.data.errorMessage,"error");
                     } 
                     this.Load = false;
                     this.VisibleVersion = false;
                }); 
    },


    async GetEnableEdit(){

      if(this.Header.noteStatus === 'New' || this.IsGroupNote){
        this.EnabledEdit = true;
      }
      
      else if(this.IsChartReview || this.Header.isVoid){
        this.EnabledEdit = false;
      }
      
      else{
        await ClinicalService.getEnableEdit(this.Header.Id,this.Header.notesId, this.IsGroupNote)
                .then((response) => {
                    if(response.status == '200'){
                       this.EnabledEdit = response.data;                                  
                    }                             
                })
                .catch((error) => {                     
                    if (!error.response) {
                   this.showMessage(this.$t('MdNote.EnabledEdit'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('MdNote.EnabledEdit'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('MdNote.EnabledEdit'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('MdNote.EnabledEdit'),error.response.data.errorMessage,"error");
                     } 
                     this.EnabledEdit = true;
                }); 
      }
    },

    async GetShowNote(){
      
      if(this.Header.noteStatus === 'New' || this.IsGroupNote){
        this.ShowNote = true;
      }
      else{
        await ClinicalService.getShowNote(this.Header.Id,this.Header.notesId,this.IsGroupNote)
                .then((response) => {
                    if(response.status == '200'){
                       this.ShowNote = response.data;
                       this.showPrint = (this.Header.noteStatus != 'New' && this.ShowNote && this.Header.customFrame != 'TFRMDOCUMENTNOTES');                                  
                    }                             
                })
                .catch((error) => {                     
                    if (!error.response) {
                   this.showMessage(this.$t('MdNote.Invisible'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('MdNote.Invisible'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('MdNote.Invisible'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('MdNote.Invisible'),error.response.data.errorMessage,"error");
                     } 
                     this.ShowNote = true;
                }); 
      }
    },
    
    ExecuteVersion(){
      if(this.Header.noteStatus !== 'New' && !this.IsGroupNote){
        if(this.NoteOptions.enableNoteVersioning && this.NoteOptions.showVersioOnlyForSupervisors){
            if(this.isSupervisor){
              this.getVersions();
            }else{
              this.VisibleVersion = false;
            }       
        }
        else if(this.NoteOptions.enableNoteVersioning && !this.NoteOptions.showVersioOnlyForSupervisors){
            this.getVersions();
        }
        else{
            this.VisibleVersion = false;
        }
      }
    },

  },

  async mounted(){ 
    this.EmployeeCode = this.$store.state.employeeCode //LocalStorage.getEmployeeCode();  
    // this.getControlsAndValues();    
    this.getNoteOption();      
    this.InitializeClass();    
    await this.getNotesHeader('');
    this.IsNew = this.Header.noteStatus === 'New'?true:false;

    await this.GetEnableEdit();
    await this.GetShowNote();    

    if (this.IsWorkFlow) {
      this.populateDataNoteWorkflow()
    } 

    if(this.Header.startTime){
      this.$set(this.DataNote.Header, 'StartTimeString', Utils.formatTime(this.Header.startTime));
    }
    if(this.Header.endTime){
      this.$set(this.DataNote.Header, 'EndTimeString', Utils.formatTime(this.Header.endTime));
    }

    if (this.ShowNote)
      this.getControlsAndValues('');
    else
    {
      this.DesignerControls = [];
      this.ArrayModel = [];
      this.VisibleActions = true;
      this.Load = false;
    }     
    
    this.getStartAndEndTime();

    if(this.Header.showBilling) {
      await this.GetTherapiesHeaderBillingInfo();
      await this.NotesTherapiesByNote();
      this.popTherapies()
      //this.populateTherapies()
    }

    this.Header.notesContents = this.Header.notesContents === null ? "" : this.Header.notesContents;
    

    this.Header.allowCosignNotesDoneByCosigners = this.IsChartReview ? false : this.Header.allowCosignNotesDoneByCosigners;
    this.$set(this.DataNote.Header, 'Servicedate', this.ServiceDate);
    this.setFacilityCode();
    this.DataNote.Header.NoteTime = this.Header.Time?this.Header.Time:null;
    this.DataNote.Header.FacilityCode = this.Header.FacilityCode?this.Header.FacilityCode:"";
    this.FacilityCode = this.Header.FacilityCode?this.Header.FacilityCode:"";
    this.DataNote.Header.Therapistcode = this.Header.therapistCode?this.Header.therapistCode:"";
    this.$set(this.DataNote.Header, 'groupCreationId', this.Header.groupCreationId);
    this.$set(this.DataNote.Header, 'Patientcode', this.Header.patientCode);
    this.$set(this.DataNote.Header, 'transCode', this.Header.transCode);

     var new_startDate= new Date();
    if(this.Header.endTime && !Utils.DateIsEmpty(this.Header.endTime)){
      this.EndTime = {
        "text": moment(this.Header.endTime).format("hh:mm a"),
        "value":moment(this.Header.endTime).format("hh:mm a")
      }
        var date_resultend = moment(new_startDate).format('YYYY-MM-DD');

        var end = Utils.convertTo24Hour(date_resultend + ' ' + this.EndTime.value);

        var InternationalTimeEnd =  new Date(date_resultend+'T'+end);
        this.$set(this.DataNote.Header, 'EndTime', moment(InternationalTimeEnd).format());
      
    }

    if(this.Header.noteStatus != 'New' && this.ShowNote && this.Header.customFrame != 'TFRMDOCUMENTNOTES'){
      this.showPrint = true;
    }

    if(this.Header.startTime /*&& !Utils.DateIsEmpty(this.Header.startTime)*/){
      this.StartTime = {
        "text":moment(this.Header.startTime).format("hh:mm a"),
        "value":moment(this.Header.startTime).format("hh:mm a")
      }
        var date_resultstart = moment(new_startDate).format('YYYY-MM-DD');

        var start = Utils.convertTo24Hour(date_resultstart + ' ' + this.StartTime.value);

        var InternationalTimeStart =  new Date(date_resultstart+'T'+start);
        this.$set(this.DataNote.Header, 'StartTime', moment(InternationalTimeStart).format());      
    }

    this.isReadOnlyDateSign = this.Header.isSigned && this.NoteOptions.allowChangingSignedDate === true ? false : true;
    this.isReadOnlyDateCoSign = this.Header.isCoSigned && this.NoteOptions.allowChangingSignedDate === true ? false : true;

    if(this.Time){
      this.TimeTitle = moment(this.Time).format('LLLL');
    }
    this.NoteIsReadOnly = (this.IsClinicalNote && this.$store.state.isClinicalNotesEHRReadOnly) || 
                          (this.IsSchedulerNote && this.$store.state.isSchedulerClinicalEHRReadOnly) || 
                          this.IsChartReview || 
                          this.CopyIsVoid || 
                          this.userHasReadOnlyAccess || 
                          (!this.EnabledEdit);

    this.SetVoidNote();
    this.ExecuteVersion();

  },

  updated () {
    this.NoteIsReadOnly = (this.IsClinicalNote && this.$store.state.isClinicalNotesEHRReadOnly) || 
                          (this.IsSchedulerNote && this.$store.state.isSchedulerClinicalEHRReadOnly) ||  
                          this.userHasReadOnlyAccess || 
                          this.CopyIsVoid || 
                          (!this.EnabledEdit) || 
                          (this.VersionModel.code > 0);

    this.Header.signedDate = this.Header.isSigned ? this.Header.signedDate : "";
    this.DataNote.Header.SignedDate = this.Header.isSigned  ? this.DataNote.Header.SignedDate : null;
    this.Header.coSignedDate = this.Header.isCoSigned ?  this.Header.coSignedDate : "";
    this.DataNote.Header.CosignedDate = this.Header.isCoSigned  ? this.DataNote.Header.CosignedDate : null;

    this.isReadOnlyDateSign = this.Header.isSigned && this.NoteOptions.allowChangingSignedDate === true ? false : true;
    this.isReadOnlyDateCoSign = this.Header.isCoSigned && this.NoteOptions.allowChangingSignedDate === true ? false : true;
    this.Header.notesContents = this.Header.notesContents === null ? "" : this.Header.notesContents;
  },

  created: async function () { 
    this.getTherapists();
  
    /*if(this.ShowCosignerTab())
        this.getCoTherapists();
    */  
    this.IsShowCosignerTab = this.ShowCosignerTab();
    if(this.IsShowCosignerTab){
      await this.getCoTherapists();
    }

    this.Load = true;   
    EventBus.$on("onSignatureDone", function (e) {  
        const code = e.ControlId;
      this.ArrayModel.map(function(control){
        if(control.ControlId == e.ControlId){
            control["VM_"+code] = e.base64;
        }
          return control;
      });     
        /*var image = this.ArrayModel.find(d => d[finalCode]  !== undefined)[finalCode];
        if(image !== undefined){
          //this.ArrayModel.push(finalCode);
          this.ArrayModel.find(d => d[finalCode] !== undefined)[finalCode] = e.base64;
        }*/
    }.bind(this));

    EventBus.$on("onActionTherapies", function (e) {         
       if(e.state === 'Add'){
          if(!this.FoundinListTherapies(e.model.code)){
            this.NotesTherapiesByNoteList.push(e.model);
            this.populateDataNoteTherapies(this.NotesTherapiesByNoteList); 
            this.NotesTherapiesByNoteListCopy = [...this.NotesTherapiesByNoteList]; 
            this.popTherapies()         
          }          
        }else{         
          this.EditTherapy(e.selected.code,e.model);
        }       
        
    }.bind(this));

     EventBus.$on("onControlWasChanged", function (e) {
        this.ArrayModel.map(function(control){
        if(control.ControlId == e.ControlId && control.NoteId && control.NoteId.trim() == e.NoteId){
          control.Changed = e.Changed;
        }
          return control;
      });
        this.setNoteIsEditing(e.NoteId,true)
     }.bind(this));

     EventBus.$on("onChanged", function (e) {         
        if (e.Tag != undefined && e.Tag.indexOf('Versions') == -1 )
        {
          if(e.Tag == this.Id) {
            this.headersChanged = e.Changed         
          }
          
          this.setNoteIsEditing(e.Tag, true)
        }
     }.bind(this));

     EventBus.$on("onSignatureClear", function (Id) {   
      this.ArrayModel.map(function(control){
        if(control.ControlId == Id){
          //control.Changed = e.Changed;
           control["VM_"+Id] = "";
        }
          return control;
      });
     }.bind(this));

     EventBus.$on("TPWileyObjectiveFromComponent", function (Id,data) { 
      this.ArrayModel.map(function(control){
        if(control.ControlId == Id){         
           control["VM_"+Id] = data;
        }
          return control;
      });
     }.bind(this));

     EventBus.$on("saveFromClinicalNotes", function (to) { 
        this.pathAfterSave = to 
        this.SaveNote()
     }.bind(this));
     

     EventBus.$on("saveFromIWF", function () { 
        this.savingFromIWF = true  
        this.saveNoteFromIWF()
     }.bind(this));

     EventBus.$on("OnAddNewTherapy", function (value) {        
        if(!this.FindTherapy(value.code)){
          this.showMessage(this.$t('MdNote.tadded'),this.$t('MdNote.tAddedMsg'),"warning");
          this.NotesTherapiesByNoteList.push({billed:false,code:value.code,name:value.name,units:1});
          this.DataNote.therapies.push({Therapycode: value.code, units: 1});
        }
     }.bind(this));

     EventBus.$on("saveFromGroupNotes", async function (item, action, to) {     
      this.pathAfterSave = to
      this.nextItemFromGroupNotes = item
      this.actionFromGroupNote = action  
      await this.saveNoteFromGroupNotes()
     }.bind(this));

     EventBus.$on("showIWFSummary", function () { 
        this.showIWFSummary()
     }.bind(this));
     //
  },

  beforeDestroy () {     
    EventBus.$off('saveFromIWF');
    EventBus.$off('saveFromGroupNotes');
    EventBus.$off('saveFromClinicalNotes');
    EventBus.$off('OnAddNewTherapy');
    
  },
}

</script>

<style>
  .SignedDate > span{
    color:black;
    font-size:12px;   
    font-weight:bold;       
  }
  .multiselect__single, input {
    white-space: nowrap;
    overflow: hidden; 
  }

  .RequiredClass{
 border: solid 2px #dc3545 !important;
  border-bottom: 2px solid red!important;
/* border-style: solid !important;
  border-color: rgb(201, 76, 76)!important;
 border-color: red !important;*/
  }
</style>